import { Tooltip } from 'antd'

// import classNames from 'classnames'
import { memo, useEffect, useRef, useState } from 'react'
import { useMemoizedFn, useRequest } from 'ahooks'
import { useChatAPI } from '../../hooks/useChatAPI'
import { cn } from '../../utils/cn'

interface TaskChatIconProps {
  agentId: string
  conversationId: string
  open?: boolean
  onClick?: () => void
}

export const TaskIcon = memo((props: TaskChatIconProps) => {
  const { agentId, conversationId, open, onClick } = props

  const ref = useRef<HTMLButtonElement>(null)
  const [tip, setTip] = useState(false)

  const { chatAPI } = useChatAPI()

  const { data, refresh } = useRequest(
    () => chatAPI.Task.getCount(conversationId, agentId),
    {
      refreshDeps: [chatAPI, agentId, conversationId],
      onSuccess(data) {
        setTip(!!data.data.is_new_task)
      },
    },
  )

  const handleClick = useMemoizedFn(() => {
    onClick?.()
    if (tip) {
      setTip(false)
      chatAPI.Task.refreshCount(conversationId)
    }
  })

  useEffect(() => {
    const handle = () => {
      refresh()
    }
    window.addEventListener('taskListUpdate', handle)

    return () => {
      window.removeEventListener('taskListUpdate', handle)
    }
  }, [])

  return (
    <Tooltip
      getPopupContainer={() => ref.current || document.body}
      open={tip}
      title='有新预设任务可用'
      rootClassName={cn(
        '[&_.ant-tooltip-inner]:!bg-primary [&_.ant-tooltip-arrow:after]:!bg-primary',
      )}
    >
      <button
        ref={ref}
        className={cn(
          'w-auto  relative flex items-center justify-center rounded-[6px] bg-transparent',
          {
            'bg-bg_3 bg-opacity-[0.08]': open,
          },
        )}
        onClick={handleClick}
      >
        <img
          className='w-[16px]'
          src='https://resource.bantouyan.com/battleyeah-ai/agent/task.svg'
        />
        {/* <IconFont className='color-[#17171D]  text-16px' name='task' /> */}
        <span className='ml-[4px] text-nowrap text-[14px]'>
          任务
          {!!data?.data?.count && (
            <span className='ml-[4px]'>{data?.data?.count}</span>
          )}
        </span>
      </button>
    </Tooltip>
  )
})

// interface TaskAgentIconProps {
//   agentId: string
//   conversationId: string
//   // getConversationId: () => string
//   onClick: () => void
// }

// export const TaskAgentIcon = memo((props: TaskAgentIconProps) => {
//   const { agentId, onClick, conversationId } = props

//   // const conversationId = useGetConversationId(getConversationId)

//   const ref = useRef<HTMLButtonElement>(null)
//   const [tip, setTip] = useState(false)

//   const { data, refresh } = useRequest(
//     () => getConversationTaskInfo(agentId, conversationId),
//     {
//       ready: !!agentId && !!conversationId,
//       refreshDeps: [agentId, conversationId],
//       onSuccess(data) {
//         setTip(!!data.is_new_task)
//       },
//     },
//   )

//   const handleClick = useMemoizedFn(() => {
//     onClick()
//     if (tip) {
//       setTip(false)
//       refreshConversationTaskInfo(conversationId)
//     }
//   })

//   useEffect(() => {
//     const handle = () => {
//       refresh()
//     }
//     window.addEventListener('taskListUpdate', handle)
//     window.addEventListener('agentRuleChange', handle)

//     return () => {
//       window.removeEventListener('taskListUpdate', handle)
//       window.removeEventListener('agentRuleChange', handle)
//     }
//   }, [])

//   return (
//     <Tooltip
//       getPopupContainer={() => ref.current || document.body}
//       open={tip}
//       title='有新预设任务可用'
//       rootClassName='[&_.ant-tooltip-inner]:bg-primary! [&_.ant-tooltip-arrow:after]:bg-primary!'
//     >
//       <span
//         ref={ref}
//         className='flex flex items-center justify-center text-14px text-#7B61FF! mr-8px cursor-pointer rounded-6px p-4px hover:bg-[rgba(98,105,153,0.08)]'
//         onClick={handleClick}
//       >
//         <IconFont name='task' className='mr-4px' />
//         <span>
//           任务{!!data?.count && <span className='ml-[4px]'>{data?.count}</span>}
//         </span>
//       </span>
//     </Tooltip>
//   )
// })
