import styled from '@emotion/styled'
import type { InputProps, InputNumberProps } from 'antd'
import { Input as AntdInput, InputNumber as AntdInputNumber } from 'antd'
import type { InputRef, PasswordProps, TextAreaProps } from 'antd/es/input'
import { rgba } from 'polished'
import { css } from '@emotion/react'
import { useRef, useState, forwardRef } from 'react'
import { isBoolean } from 'lodash-es'
import { THEME } from '@bty/ai-shared'
import { Size, TextSize } from '../const'

interface StyledProps extends InputProps {
  theme: typeof THEME
}

export function sharedStyles(props: StyledProps) {
  return css`
    height: ${Size[`${props.size}`]};
    font-size: ${TextSize[`${props.size}`]};
    border-radius: ${props.size === 'large' ? '8px' : '6px'};
    background-color: ${rgba(THEME.colors.bg_3, 0.06)};
    border-color: transparent;
    &.ant-input-disabled {
      border: none;
      background: ${rgba(THEME.colors?.bg_3, 0.06)};
    }

    .ant-input-suffix {
      font-size: ${props.size === 'small' ? '10px' : '12px'};
      color: ${rgba(THEME.colors?.font_1, 0.6)};
      transform: scale(90%);
      .ant-input-show-count-suffix {
        /* color: ${rgba(THEME.colors.font_1, 0.6)};
        transform: scale(90%); */
      }
    }

    &.ant-input-outlined:focus,
    &.ant-input-outlined:hover {
      border-color: ${THEME.colors.primary};
    }

    &.ant-input-outlined:focus {
      box-shadow: 0px 0px 0px 2px ${rgba(THEME.colors.primary, 0.1)};
    }

    &.ant-input-affix-wrapper:hover {
      border-color: ${THEME.colors.primary};
    }

    &.ant-input-affix-wrapper-focused:not(
        .ant-input-affix-wrapper-status-error
      ),
    &.ant-input-affix-wrapper-focused:not(
        .ant-input-affix-wrapper-status-error
      ):hover {
      && {
        border-color: ${THEME.colors.primary};
        box-shadow: 0px 0px 0px 1px ${rgba(THEME.colors.primary, 0.2)};
      }
    }
  `
}

const StyledAntInput = styled(AntdInput)`
  ${props => sharedStyles(props as any)};
  .ant-input {
    background-color: transparent;
  }
`

const StyledAntInputNumber = styled(AntdInputNumber)`
  ${props => sharedStyles(props as any)};
  width: 100%;

  && .ant-input-number-input {
    height: ${props => Size[`${props.size}`]};
  }
`

const StyledAntTextArea = styled(AntdInput.TextArea)`
  ${props => sharedStyles(props as any)};

  height: auto;
  .ant-input {
    resize: none;
    padding-bottom: 24px;
    background-color: transparent;
  }

  .ant-input-suffix {
    position: relative;
    top: -30px;
    left: -12px;
  }
`

const StyledPasswordInput = styled(AntdInput.Password)`
  ${props => sharedStyles(props as any)};

  .ant-input {
    background-color: transparent;
  }
`

const commonClassName = 'bg-bg_3 bg-opacity-[0.6] border-transparent'

export const Input = forwardRef<InputRef, InputProps>((props, ref) => {
  return (
    <StyledAntInput
      ref={ref}
      {...props}
      className={`${props.className ?? ''} ${commonClassName}`}
    />
  )
})

export const TextArea = forwardRef<any, TextAreaProps>(
  (props: TextAreaProps, ref) => {
    const isInChinese = useRef<boolean>(false)
    const [currentValue, setCurrentValue] = useState<TextAreaProps['value']>('')
    const handleOnCompositionStart = (
      e: React.CompositionEvent<HTMLTextAreaElement>,
    ) => {
      setCurrentValue(props.value)
      isInChinese.current = true
      props.onCompositionStart?.(e)
    }
    const handleOnCompositionEnd = (
      e: React.CompositionEvent<HTMLTextAreaElement>,
    ) => {
      isInChinese.current = false
      props.onCompositionEnd?.(e)
    }
    const handleOnBlur = (
      e: React.FocusEvent<HTMLTextAreaElement, Element>,
    ) => {
      setCurrentValue('')
      props.onBlur?.(e)
    }
    return (
      <StyledAntTextArea
        {...props}
        ref={ref}
        onCompositionStart={handleOnCompositionStart}
        onCompositionEnd={handleOnCompositionEnd}
        onBlur={handleOnBlur}
        showCount={
          !props.showCount
            ? false
            : isBoolean(props.showCount)
              ? {
                  formatter: ({ count, maxLength }) => {
                    return (
                      <>{`${
                        isInChinese.current
                          ? String(currentValue || '').length
                          : count
                      } / ${maxLength}`}</>
                    )
                  },
                }
              : props.showCount
        }
        className={`${props.className} ${commonClassName}`}
      />
    )
  },
)

export const InputPassword = forwardRef<any, PasswordProps>((props, ref) => {
  return (
    <StyledPasswordInput
      {...props}
      ref={ref}
      className={`${props.className} ${commonClassName}`}
    />
  )
})

export function InputNumber(props: InputNumberProps) {
  return (
    <StyledAntInputNumber
      {...props}
      className={`${props.className ?? ''} ${commonClassName}`}
    />
  )
}
