import type { BaseBusinessType } from '../types/base'
import { FetchRequest } from './lib/lib'

export type User = any
export function updateUser({ env, auth }: BaseBusinessType, data: User) {
  const request = new FetchRequest(auth, env)

  return request.put('/v1/users/me', data)
}

export function getUser({ env, auth }: BaseBusinessType) {
  const request = new FetchRequest(auth, env)

  return request.get<User>('/v1/users/me')
}
