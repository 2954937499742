// src/constant/theme.ts
var THEME = {
  colors: {
    // 背景颜色
    bg: "#F7F7FA",
    // 一般做大背景色
    bg_1: "#EEEEF6",
    // 背景上层图层的hover色，eg:右侧面板的hover色
    bg_2: "#F5F5FA",
    // 白色背景的hover色
    bg_3: "#626999",
    // 输入框背景色等, 一般需要配合透明度一起使用
    bg_dark: "#3F3F44",
    // toast, 气泡等等颜色
    // 边框分割线
    line: "#E1E1E5",
    // 分割线颜色, 一般使用要配合透明度一起使用
    // 图标&文本颜色
    font: "#17171d",
    // 最常用的文本颜色
    font_1: "#8D8D99",
    // 次级文本的颜色，次级标题，图标等等
    font_2: "#979797",
    font_red: "#E54A17",
    // 红色文本
    // 主色调-紫色
    primary: "#7B61FF",
    // 紫色强调色
    // 强调色
    success: "#00B042",
    //  成功色
    error: "#FF5219",
    // 红色，一般是错误按钮
    warning: "#FE9700",
    // 金黄色，警告使用
    tips: "#38A6FF",
    // 蓝色，标记使用, 例如：高亮块
    // 辅助色
    green: "#25BC68",
    // 绿色
    orange: "#ED9B23",
    // 橘色
    pink: "#E55C91",
    // 粉色
    brown: "#B87332",
    // 棕色
    // flow按钮
    btn_white: "#f7f7ff"
  }
};
var ANTD_TOKEN = {
  wireframe: false,
  colorInfo: THEME.colors.primary,
  colorPrimaryActive: THEME.colors.primary,
  colorPrimaryBorderHover: THEME.colors.primary,
  colorPrimary: THEME.colors.primary,
  colorText: THEME.colors.font,
  colorPrimaryHover: THEME.colors.primary,
  borderRadius: 8,
  colorPrimaryBg: THEME.colors.bg_1,
  fontSize: 14,
  colorError: THEME.colors.error,
  colorWarning: THEME.colors.warning
};

// src/types/task.ts
var TaskType = /* @__PURE__ */ ((TaskType2) => {
  TaskType2["FLOW"] = "flow";
  TaskType2["PLUGIN"] = "plugin";
  TaskType2["COMMAND"] = "command";
  TaskType2["AGENT"] = "agent";
  return TaskType2;
})(TaskType || {});
var ExecuteType = /* @__PURE__ */ ((ExecuteType2) => {
  ExecuteType2["IMMEDIATELY"] = "IMMEDIATELY";
  ExecuteType2["SCHEDULE"] = "SCHEDULE";
  return ExecuteType2;
})(ExecuteType || {});
var LoopType = /* @__PURE__ */ ((LoopType2) => {
  LoopType2["NONE"] = "NO_REPEAT";
  LoopType2["DAILY"] = "DAILY";
  LoopType2["WEEKLY"] = "WEEKLY";
  LoopType2["MONTHLY"] = "MONTHLY";
  LoopType2["WORKDAY"] = "WEEKDAY";
  LoopType2["CUSTOM"] = "CUSTOM";
  return LoopType2;
})(LoopType || {});
var CustomLoopType = /* @__PURE__ */ ((CustomLoopType2) => {
  CustomLoopType2["MINUTE"] = "MINUTE";
  CustomLoopType2["HOUR"] = "HOUR";
  CustomLoopType2["WEEK"] = "WEEK";
  return CustomLoopType2;
})(CustomLoopType || {});
var TaskStatusType = /* @__PURE__ */ ((TaskStatusType2) => {
  TaskStatusType2["SUCCEEDED"] = "SUCCEEDED";
  TaskStatusType2["FAILED"] = "FAILED";
  TaskStatusType2["PENDING"] = "PENDING";
  TaskStatusType2["RUNNING"] = "RUNNING";
  return TaskStatusType2;
})(TaskStatusType || {});

// src/constant/file.ts
var FILE_MIME_TYPE_MAP = {
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ppt: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  pdf: "application/pdf",
  jpeg: "image/jpeg",
  jpg: "image/jpg",
  png: "image/png",
  gif: "image/gif",
  webp: "image/webp",
  svg: "image/svg+xml",
  html: "text/html",
  mp3: "audio/mp3",
  mpeg: "audio/mpeg",
  wav: "audio/wav",
  aac: "audio/aac",
  ogg: "audio/ogg",
  webm: "audio/webm",
  m4a: "audio/m4a",
  "x-m4a": "audio/x-m4a",
  wma: "audio/wma",
  "x-ms-wma": "audio/x-ms-wma",
  amr: "audio/amr",
  flac: "audio/flac",
  mp4: "video/mp4",
  avi: "video/avi",
  "x-avi": "video/x-msvideo",
  mov: "video/quicktime",
  flv: "video/x-flv",
  wmv: "video/x-ms-wmv",
  "v-webm": "video/webm",
  "v-ogg": "video/ogg",
  "x-msvideo": "video/x-msvideo",
  "x-mpeg": "video/mpeg",
  "x-matroska": "video/x-matroska",
  "x-ms-asf": "video/x-ms-asf",
  zip: "application/zip",
  rar: "application/x-rar-compressed",
  csv: "text/csv"
};

// src/utils/file.ts
function getFileVariant(type) {
  switch (type) {
    case FILE_MIME_TYPE_MAP.docx:
      return "doc";
    case FILE_MIME_TYPE_MAP.pdf:
      return "pdf";
    case FILE_MIME_TYPE_MAP.gif:
    case FILE_MIME_TYPE_MAP.jpeg:
    case FILE_MIME_TYPE_MAP.jpg:
    case FILE_MIME_TYPE_MAP.png:
    case FILE_MIME_TYPE_MAP.webp:
      return "image";
    case FILE_MIME_TYPE_MAP.html:
      return "html";
    case FILE_MIME_TYPE_MAP.svg:
      return "svg";
    case FILE_MIME_TYPE_MAP.ppt:
    case FILE_MIME_TYPE_MAP.pptx:
      return "ppt";
    case FILE_MIME_TYPE_MAP.mp3:
    case FILE_MIME_TYPE_MAP.mpeg:
    case FILE_MIME_TYPE_MAP.wav:
    case FILE_MIME_TYPE_MAP.aac:
    case FILE_MIME_TYPE_MAP.ogg:
    case FILE_MIME_TYPE_MAP.webm:
    case FILE_MIME_TYPE_MAP.m4a:
    case FILE_MIME_TYPE_MAP["x-m4a"]:
    case FILE_MIME_TYPE_MAP["x-ms-wma"]:
    case FILE_MIME_TYPE_MAP.wma:
    case FILE_MIME_TYPE_MAP.amr:
    case FILE_MIME_TYPE_MAP.flac:
      return "audio";
    case FILE_MIME_TYPE_MAP.mp4:
    case FILE_MIME_TYPE_MAP.avi:
    case FILE_MIME_TYPE_MAP.mov:
    case FILE_MIME_TYPE_MAP["v-webm"]:
    case FILE_MIME_TYPE_MAP["v-ogg"]:
    case FILE_MIME_TYPE_MAP["x-msvideo"]:
    case FILE_MIME_TYPE_MAP["x-mpeg"]:
    case FILE_MIME_TYPE_MAP["x-matroska"]:
    case FILE_MIME_TYPE_MAP["x-ms-asf"]:
      return "video";
    default:
      return "txt";
  }
}
function getFileVariantFromUrlOrFileName(url, defaultType, fileName) {
  var _a, _b, _c, _d, _e;
  let valuePathArr = [];
  try {
    valuePathArr = ((_b = (_a = new URL(url)) == null ? void 0 : _a.pathname) == null ? void 0 : _b.split("/")) ?? [];
  } catch (e) {
  }
  const valueFileName = valuePathArr[valuePathArr.length - 1];
  const _fileName = fileName ?? valueFileName ?? "\u672A\u77E5\u5185\u5BB9.txt";
  const fileSuffix = (_e = (_d = (_c = _fileName == null ? void 0 : _fileName.split(".")) == null ? void 0 : _c.slice) == null ? void 0 : _d.call(_c, -1)) == null ? void 0 : _e[0];
  const mimeType = FILE_MIME_TYPE_MAP[fileSuffix ?? defaultType];
  return getFileVariant(mimeType);
}

// src/utils/parse.ts
function tryParseToJsonObject(text) {
  try {
    return JSON.parse(text);
  } catch {
  }
  return text;
}
function tryParseToObject(json) {
  if (typeof json === "object")
    return json;
  try {
    return JSON.parse(json);
  } catch (e) {
    return {};
  }
}
export {
  ANTD_TOKEN,
  CustomLoopType,
  ExecuteType,
  LoopType,
  THEME,
  TaskStatusType,
  TaskType,
  getFileVariant,
  getFileVariantFromUrlOrFileName,
  tryParseToJsonObject,
  tryParseToObject
};
