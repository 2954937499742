import { memo } from 'react'
import { useMemoizedFn } from 'ahooks'
import { Spin, Button } from 'antd'
import type { TaskListResult } from '@bty/chat-fetch'

// import { DEFAULT_OVERLAY_SCROLLBAR_OPTIONS } from '@/hooks/useScrollBar'
// import emptyImg from '@/assets/empty.png'
import { cn } from '../../utils/cn'
import { emptyImg } from '../../const'
import { Icon } from '../base/Icon'
import { TaskListItem } from './TaskListItem'

interface TaskListProps {
  agentId: string
  list: TaskListResult[]
  loading?: boolean
  conversationId: string
  layout?: 'desktop' | 'mobile'
  toEdit: (task?: TaskListResult) => void
  toLog: (task: TaskListResult) => void
  onClose?: () => void
  onUpdateList?: (refresh?: boolean, task?: TaskListResult) => void
  className?: string
}

export const TaskList = memo((props: TaskListProps) => {
  const {
    list,
    loading,
    conversationId,
    layout,
    toEdit,
    toLog,
    onClose,
    onUpdateList,
    className,
  } = props

  const handleNew = useMemoizedFn(() => {
    toEdit()
  })

  return (
    <div
      className={cn(
        className,
        'w-full flex flex-col h-full overflow-hidden text-[14px]',
      )}
    >
      <div className='flex justify-center items-center h-[64px] px-[16px] flex-none'>
        <span
          className={cn('font-[600] mr-auto text-[16px]', {
            'ml-[16x]': layout === 'mobile',
          })}
        >
          任务
        </span>
        {layout === 'desktop' && (
          <Button
            className='font-normal mr-[16px] !px-[8px]'
            type='primary'
            onClick={handleNew}
          >
            新建任务
          </Button>
        )}
        <button
          className='inline-flex items-center justify-center cursor-pointer w-[24px] h-[24px] hover:bg-bg_3 hover:bg-opacity-[0.08] rounded-[6px] bg-transparent'
          onClick={onClose}
        >
          <Icon path='close.svg' />
        </button>
      </div>
      <div className='flex-1 overflow-hidden  pb-[16px]'>
        <div
          className='overflow-auto overflow-x-hidden h-full px-[16px]'
          // element='div'
          // options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
          // defer
        >
          <Spin spinning={loading} wrapperClassName='h-full'>
            {list?.map(each => (
              <TaskListItem
                className={cn('hover:bg-bg_3 hover:bg-opacity-[0.08]', {
                  '!border-none !py-[13px]': layout === 'mobile',
                })}
                key={each.task_id}
                task={each}
                conversationId={conversationId}
                onSelect={toLog}
                onEdit={toEdit}
                onUpdateList={onUpdateList}
              />
            ))}
            {!list.length && !loading && (
              <div className='h-[60%] flex items-center justify-center flex-col'>
                <img className='w-[128px]' src={emptyImg} alt='' />
                <div className='text-font_1 text-opacity-[0.6]'>暂无任务</div>
              </div>
            )}
          </Spin>
        </div>
      </div>
      {layout === 'mobile' && (
        <div className='pl-[16px] pb-[16px] w-full flex'>
          <Button
            className='font-normal mr-[16px] w-full'
            type='primary'
            onClick={handleNew}
          >
            新建任务
          </Button>
        </div>
      )}
    </div>
  )
})
