import { useThrottleFn } from 'ahooks'
import { useEffect, type RefObject } from 'react'

export function useResize<T extends HTMLElement>(
  dom: RefObject<T>,
  onResize: () => void,
  gutter = 16,
) {
  const { run: throttleCall } = useThrottleFn(onResize, { wait: gutter })

  useEffect(() => {
    if (!dom.current) return

    throttleCall()
    const observe = new ResizeObserver(throttleCall)
    observe.observe(dom.current)

    return () => {
      observe.disconnect()
    }
  }, [!!dom.current, throttleCall])
}
