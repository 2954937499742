import classNames from 'classnames'
import type { FC } from 'react'
import { forwardRef, useMemo, useRef } from 'react'
import { useKeyPress } from 'ahooks'
import { Tooltip } from 'antd'
import { IconFont } from '../../icon-font'
import { isMac } from '../../../utils/device'
import { moreRef } from '../../../utils/react'
import { Delete } from '../components/Delete'
import { Role } from '../components/Role'
import type { TextEditPureProps } from './type'
import type { TextEditorRef } from './editor'
import { TextEditorPure } from './editor'
import './style'

const FULLSCREEN_KEY = isMac ? 'meta.k' : 'ctrl.k'

export interface Variable {
  label: string
  type?: string
  hideQuickSelect?: boolean
}

export interface TextEditorProps extends TextEditPureProps {
  'aria-invalid'?: string
  'aria-required'?: string
  error?: boolean
  variables?: Variable[]
  anchor?: 'left' | 'right'
  variableTipsContainer?: HTMLElement | null
  variableTipsOffset?: [number, number]
  role?: string
  onRoleClick?: (role: string) => void
  showDelete?: boolean
  onDelete?: () => void
  showFullscreen?: boolean
  fullscreen?: boolean
  onFullscreenChange?: (isFullscreen: boolean) => void
  innerTooltipContent?: React.ReactNode
}

export const TextEditor: FC<TextEditorProps> = forwardRef((props, ref) => {
  const {
    'aria-invalid': ariaInvalid,
    error,
    variables,
    // anchor = 'left',
    // variableTipsOffset,
    variableTipsContainer,
    value,
    role,
    onRoleClick,
    showDelete,
    onDelete,
    showFullscreen,
    fullscreen,
    onFullscreenChange,
    className,
    style,
    ...rest
  } = props

  const hasError = useMemo(
    () => error || ariaInvalid === 'true',
    [error, ariaInvalid],
  )

  const editorRef = useRef<TextEditorRef>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  // const isFocusWithin = useFocusWithin(containerRef)
  // const [
  //   variableTipsVisible,
  //   { setTrue: showVariableTips, setFalse: hideVariableTips },
  // ] = useBoolean(false)

  const tipMap = useMemo(() => {
    const tips = (variables || [])
      .filter(item => !item.hideQuickSelect)
      .map(each => ({
        value: `{{${each.label}}}`,
        color: each.type === 'node' ? '#38A6FF' : '#7B61FF',
        type: each.type,
      }))

    return {
      '/': tips,
      '{{': tips,
    }
  }, [variables])

  // const handleAddTip = useMemoizedFn((tag: TagProps) => {
  //   editorRef.current?.insertText(`{{${tag.label}}}`)
  // })

  useKeyPress(
    FULLSCREEN_KEY,
    event => {
      event.preventDefault()
      if (!showFullscreen) return
      onFullscreenChange?.(!fullscreen)
    },
    { useCapture: true },
  )

  return (
    <>
      {/* {(isFocusWithin || variableTipsVisible) && !!variables?.length && (
        <div onMouseEnter={showVariableTips} onMouseLeave={hideVariableTips}>
          <TagsTooltip
            rootClassName='z-1'
            variables={variables.filter(item => !item.hideQuickSelect)}
            anchor={anchor}
            topEl={containerRef.current}
            offset={variableTipsOffset}
            leftEl={variableTipsContainer || containerRef.current}
            onTagClick={handleAddTip}
          />
        </div>
      )} */}

      <div
        className={classNames('flex text-editor-form relative', className, {
          'text-editor-form-error': hasError,
        })}
        style={style}
        ref={containerRef}
      >
        {role && (
          <div className='mt-[1px] mr-[4px]'>
            <Role
              readOnly={props.readonly}
              role={role}
              onClick={() => onRoleClick?.(role)}
            />
          </div>
        )}

        <TextEditorPure
          tipsContainer={variableTipsContainer}
          {...rest}
          ref={moreRef(editorRef, ref)}
          value={value}
          tipMap={tipMap}
        />

        {showDelete && (
          <div className='text-editor-delete m-t-[-4px] w-[20px'>
            <Delete onClick={onDelete} />
          </div>
        )}

        {rest?.innerTooltipContent && rest?.innerTooltipContent}

        {showFullscreen && (
          <Tooltip title='⌘+K' destroyTooltipOnHide>
            <button
              className='c-bg_3/60 text-[16px] absolute right-[8px] top-[8px] z-10 flex items-center justify-center cursor-pointer w-[24px] h-[24px] rounded-[4px] hover:bg-bg_3/8'
              type='button'
              onClick={event => {
                event.stopPropagation()
                onFullscreenChange?.(!fullscreen)
              }}
            >
              <IconFont name={fullscreen ? 'suoxiaocongkou' : 'fangtai-1'} />
            </button>
          </Tooltip>
        )}
      </div>
    </>
  )
})

TextEditor.displayName = 'TextEditor'
