import classNames from 'classnames'
import type { ChangeEvent, FocusEvent, KeyboardEvent } from 'react'
import {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useMemoizedFn } from 'ahooks'
import { wait } from '../../../utils/wait'
import { useResize } from '../../../hooks/useResize'
import type { TextEditPureProps, TipItem, TrieNode } from './type'
import { createTireTip, getTireTips } from './util'
import { Scrollbar } from './components/scrollbar'
import type { TipRef } from './components/tip'
import { Tip } from './components/tip'

export interface TextEditorRef {
  insertText: (text: string) => void
}

export const TextEditorInner = forwardRef<TextEditorRef, TextEditPureProps>(
  (props, ref) => {
    const {
      minHeight,
      height,
      maxHeight,
      onHeightChange,

      value: outValue,
      onChange,
      onFocus,
      onBlur,
      onKeyDown,

      placeholder,
      disabled,
      readonly,

      tipMap,
      tipsContainer,
      rows,

      lastCursor,
      updateToBottom,
      focusScroll,
      className,
      contentClassName,
    } = props

    const editorDomRef = useRef<HTMLDivElement>(null)
    const textareaRef = useRef<HTMLTextAreaElement>(null)
    const contentRef = useRef<HTMLDivElement>(null)
    const scrollContentRef = useRef<HTMLDivElement>(null)
    const trieText = useRef<string>('')
    const trieTree = useRef<TrieNode>()
    const tipRef = useRef<TipRef>(null)
    const [focus, setFocus] = useState<boolean>(false)
    const [value, setValue] = useState(outValue ?? '')
    const [cursor, setCursor] = useState(0)
    const [tips, setTips] = useState<TipItem[]>([])

    const canEdit = useMemo(() => !disabled && !readonly, [disabled, readonly])
    const tipTire = useMemo(() => createTireTip(tipMap), [tipMap])
    const allTips = useMemo(() => getTireTips(tipTire), [tipMap])

    const highlightText = useMemo(() => {
      let result = value

      if (!result) {
        return '\n'
      }

      // div 处理最后一行的 \n 与 textarea 不一致，统一一下
      if (result[result.length - 1] === '\n') result += '\r'
      // 字符转义
      result = result.replace(/&/g, '&amp;')
      result = result.replace(/</g, '&lt;')
      result = result.replace(/>/g, '&gt;')

      if (lastCursor) {
        result += '<span class="text-editor-last-cursor">&nbsp;</span>'
      }

      if (!allTips || !allTips.length) return result

      allTips.forEach(({ value, color }) => {
        result = result.replace(
          RegExp(value, 'g'),
          `<span style="color: ${color}">$&</span>`,
        )
      })

      return result
    }, [allTips, value, lastCursor])

    const cursorText = useMemo(() => {
      if (!tips || !tips.length) return ''

      let result = value

      // div 处理最后一行的 \n 与 textarea 不一致，统一一下
      if (result[result.length - 1] === '\n') result += '\r'

      if (cursor >= 0) {
        let prev = result.slice(0, cursor + 1)
        prev = prev.replace(/&/g, '&amp;')
        prev = prev.replace(/</g, '&lt;')
        prev = prev.replace(/>/g, '&gt;')
        let next = result.slice(cursor + 1)
        next = next.replace(/&/g, '&amp;')
        next = next.replace(/</g, '&lt;')
        next = next.replace(/>/g, '&gt;')

        result = [prev, '<span class="text-editor-cursor"></span>', next].join(
          '',
        )
      }

      return result
    }, [tips, cursor, value])

    const handleValueChange = useMemoizedFn((newValue: string) => {
      if (value == null) {
        setValue(newValue)
      }
      onChange?.(newValue)
    })

    const resetTips = useMemoizedFn(async (newTips: TipItem[]) => {
      if (!textareaRef.current) return
      setTips(newTips)
      tipRef.current!.setTipSelect(0)

      if (!newTips || !newTips.length) {
        trieText.current = ''
        trieTree.current = undefined
        setCursor(-1)
      } else {
        setCursor(textareaRef.current.selectionStart)
      }
    })

    const handleFocusInsert = useMemoizedFn(async (text: string) => {
      if (!textareaRef.current) return
      const offset = textareaRef.current.selectionStart
      const prev = value.slice(0, offset - (trieText.current?.length || 0))
      const last = value.slice(offset)

      const newValue = [prev, text, last].join('')

      handleValueChange(newValue)
      resetTips([])

      await wait(100)
      textareaRef.current.setSelectionRange(
        prev.length + text.length,
        prev.length + text.length,
      )
      textareaRef.current.focus()
    })

    const handleTipSelect = useMemoizedFn(async (index: number) => {
      const text = tips[index].value
      if (!text) return
      handleFocusInsert(text)
    })

    const handleArrow = useMemoizedFn(
      (event: KeyboardEvent<HTMLTextAreaElement>) => {
        const key = event.key
        if (key === 'Enter') {
          const nowRows = value.split('\n').length
          if (rows && nowRows >= rows) {
            event.preventDefault()
          }
        }

        if (!tips || !tips.length) return false
        if (key !== 'ArrowUp' && key !== 'ArrowDown' && key !== 'Enter') {
          return false
        }

        event.preventDefault()

        if (key === 'ArrowUp') {
          tipRef.current!.setTipSelect(prev => (prev === 0 ? prev : prev - 1))
        }

        if (key === 'ArrowDown') {
          tipRef.current!.setTipSelect(prev =>
            prev === tips.length - 1 ? prev : prev + 1,
          )
        }

        if (key === 'Enter') {
          handleTipSelect(tipRef.current!.getTipSelect())
          const nowRows = value.split('\n').length
          if (rows && nowRows > rows) {
            return false
          }
        }

        return true
      },
    )

    const handleKeyDown = useMemoizedFn(
      (event: KeyboardEvent<HTMLTextAreaElement>) => {
        onKeyDown?.(event)
        const key = event.key
        const block = handleArrow(event)

        if (block) return

        if (key.length !== 1) {
          resetTips([])
          return
        }

        const parentTrie = trieTree.current || tipTire
        if (!parentTrie.children?.some(each => each.char === key)) {
          resetTips([])
          return
        }
        trieTree.current = parentTrie.children.find(each => each.char === key)
        trieText.current += key
        resetTips(getTireTips(trieTree.current))
      },
    )

    const handleChange = useMemoizedFn(
      (event: ChangeEvent<HTMLTextAreaElement>) => {
        handleValueChange(event.target.value)
      },
    )

    const handleFocus = useMemoizedFn((e: FocusEvent<HTMLTextAreaElement>) => {
      setFocus(true)
      onFocus?.(e)
    })

    const handleBlur = useMemoizedFn(
      async (e: FocusEvent<HTMLTextAreaElement>) => {
        onBlur?.(e)
        setFocus(false)
        await wait(300)
        resetTips([])
      },
    )

    useEffect(() => {
      setValue(outValue ?? '')

      if (updateToBottom) {
        if (!scrollContentRef.current) return
        scrollContentRef.current.scrollTop =
          scrollContentRef.current.scrollHeight
      }
    }, [outValue])

    useEffect(() => {
      const handleMouse = async (event: any) => {
        event.stopPropagation()
        await wait(30)
        resetTips([])
      }

      const handleWheel = (event: any) => {
        if (!event.ctrlKey && focusScroll && focus) {
          event.stopPropagation()
        }
      }

      editorDomRef.current?.addEventListener('mousedown', handleMouse)
      editorDomRef.current?.addEventListener('wheel', handleWheel)

      return () => {
        editorDomRef.current?.removeEventListener('mousedown', handleMouse)
        editorDomRef.current?.removeEventListener('wheel', handleWheel)
      }
    }, [focus])

    useImperativeHandle(ref, () => ({
      insertText: handleFocusInsert,
      focus: textareaRef.current?.focus,
    }))

    useResize(contentRef, () => {
      if (!contentRef.current) return
      onHeightChange?.(contentRef.current?.offsetHeight)
    })

    return (
      <div
        ref={editorDomRef}
        className={classNames('text-editor', className, {
          'text-editor-disabled': disabled,
        })}
      >
        <div className='text-editor-scroll-wrapper'>
          <div
            ref={scrollContentRef}
            className={classNames(contentClassName, 'text-editor-scroll')}
            style={{
              minHeight,
              height,
              maxHeight,
              overflow: focusScroll && !focus ? 'hidden' : 'auto',
            }}
          >
            <div
              className='text-editor-wrapper'
              ref={contentRef}
              style={{ minHeight }}
            >
              {canEdit && cursorText && (
                <div
                  className='text-editor-content-cursor'
                  dangerouslySetInnerHTML={{ __html: cursorText }}
                />
              )}

              {canEdit && (
                <textarea
                  ref={textareaRef}
                  rows={rows}
                  className='text-editor-input'
                  // placeholder={placeholder}
                  value={outValue ?? value}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyDown}
                  onChange={handleChange}
                />
              )}

              {!(outValue ?? value) && placeholder && (
                <div className='text-editor-placeholder'>{placeholder}</div>
              )}

              <div
                className='text-editor-content'
                dangerouslySetInnerHTML={{ __html: highlightText }}
              />
            </div>
          </div>

          <Tip
            ref={tipRef}
            tips={tips}
            tipsContainer={tipsContainer}
            onSelect={handleTipSelect}
            editorDomRef={editorDomRef}
          />

          <Scrollbar scrollRef={scrollContentRef} />
        </div>
      </div>
    )
  },
)

export const TextEditorPure = memo(TextEditorInner)
TextEditorPure.displayName = 'TextEditorPure'
