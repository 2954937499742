export function mapToMonitorEnvironment(name) {
    switch (name) {
        case 'local':
            return 'local';
        case 'development':
        case 'dev':
            return 'daily';
        case 'pre':
            return 'pre';
        case 'production':
        case 'prod':
            return 'prod';
        default:
            return 'local';
    }
}
