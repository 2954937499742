import { Form, message } from 'antd'
import React, { useEffect, useMemo } from 'react'
import { Input, Button } from '@bty/components'
import { useRequest } from 'ahooks'
import type { BaseBusinessComponentProps } from '../../types/base'
import { useBusinessContext } from '../../hooks/useBusinessContext'
import { getUser, updateUser, type User } from '../../apis/user'
import { cn } from '../../utils/cn'
import { BusinessUpload } from '../business-upload'
import { Avatar } from './avatar'

interface FormItemAvatarProps extends BaseBusinessComponentProps {
  value?: string
  onChange?: (value?: string) => void
}

function FormItemAvatar({ value, onChange }: FormItemAvatarProps) {
  const username = Form.useWatch<string>('username')

  return (
    <div className='flex items-center'>
      <Avatar name={username} src={value} size='large' />
      <BusinessUpload
        onChange={onChange}
        accept='image/*'
        multiple={false}
        maxCount={1}
        showUploadList={false}
      >
        <Button
          className='ml-[10px] p-0 w-[76px] h-[36px] text-center font-medium border-line rounded-[8px]'
          htmlType='button'
        >
          更换头像
        </Button>
      </BusinessUpload>
    </div>
  )
}

type FormValues = Pick<User, 'avatar' | 'username'>

interface FormProfileProps {
  onOk?: (user: User) => void
  className?: string
}

function InternalFormProfile({ onOk, className }: FormProfileProps) {
  const [form] = Form.useForm<FormValues>()
  const { env, auth } = useBusinessContext()

  const { data } = useRequest(() => getUser({ env, auth }))

  const user = useMemo(() => data?.data || {}, [data])

  const formUserName = Form.useWatch('username', form)
  const formAvatar = Form.useWatch('avatar', form)

  useEffect(() => {
    user &&
      form.setFieldsValue({
        avatar: user.avatar,
        username: user.username,
      })
  }, [user])
  const handleFinish = async (values: FormValues) => {
    updateUser({ env, auth }, values)
      .then(() => {
        message.success('保存成功')
        onOk?.({ ...user, ...values })
      })
      .catch(_ => {
        message.error('保存失败')
      })
  }

  const disabled = useMemo(() => {
    return formUserName === user.username && formAvatar === user.avatar
  }, [formUserName, formAvatar, user])

  return (
    <Form
      className={cn(className)}
      form={form}
      initialValues={{
        avatar: user.avatar,
        username: user.username,
      }}
      layout='vertical'
      requiredMark={false}
      onFinish={handleFinish}
    >
      <Form.Item
        className='[&_.ant-form-item-label]:font-medium'
        name='avatar'
        label='头像'
      >
        <FormItemAvatar />
      </Form.Item>
      <Form.Item
        className='[&_.ant-form-item-label]:font-medium'
        name='username'
        label='昵称'
        rules={[{ required: true, message: '请输入昵称' }]}
      >
        <Input
          className='!w-[278px]'
          size='large'
          placeholder='请输入昵称'
          maxLength={50}
        />
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {_form => (
          <Button
            className='w-[92px] rounded-[8px] !text-14px font-medium not-disabled:hover:opacity-80 disabled:!text-white disabled:!bg-[#7b61ff] disabled:!bg-opacity-40'
            type='primary'
            size='large'
            disabled={disabled}
            htmlType='submit'
          >
            保存
          </Button>
        )}
      </Form.Item>
    </Form>
  )
}

export const UserProfile = React.memo(InternalFormProfile)
