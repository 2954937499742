import { memo, useEffect, useMemo } from 'react'

import { useMemoizedFn } from 'ahooks'
import { Empty, Button } from 'antd'
import type { TaskListResult } from '@bty/ai-shared'
import { TaskType } from '@bty/ai-shared'

// import { DEFAULT_OVERLAY_SCROLLBAR_OPTIONS } from '@/hooks/useScrollBar'
// import { addTask, getTask, getTaskListDetail, updateTask } from '@/apis/task'
import type { ChatProps } from '@bty/chat-ui'
import { useChatAPI } from '../../hooks/useChatAPI'
import { Icon } from '../base/Icon'
import { TaskSetting } from './TaskSetting'
import { TaskInput } from './TaskInput'
import { useTaskData } from './TaskContext'
import { DEFAULT_TASK_CONFIG } from './const'

interface TaskDetailProps {
  agentId: string
  versionId: string
  task?: TaskListResult
  conversationId: string
  onFileUpload?: ChatProps['onFileUpload']
  onUpdateList: (refresh?: boolean, task?: TaskListResult) => void
  onUpdate: (task: TaskListResult) => void
  onBack: () => void
  onClose?: () => void
}

export const TaskDetail = memo((props: TaskDetailProps) => {
  const {
    agentId,
    versionId,
    task,
    conversationId,
    onUpdateList,
    onUpdate,
    onBack,
    onFileUpload,
    onClose,
  } = props

  const disabled = useMemo(() => task?.is_preset, [task])

  const { chatAPI } = useChatAPI()

  const { form, getRunParam, resetConfig } = useTaskData()

  const handleConfirm = useMemoizedFn(async () => {
    try {
      await form.validateFields()
      const taskConfig = form.getFieldsValue()

      const runParam = await getRunParam()

      let newTaskId = task?.task_id

      if (task?.task_id) {
        newTaskId = task.task_id
        await chatAPI.Task.update({
          task_id: task.task_id,
          flow_id: agentId,
          conversation_id: conversationId,
          preset_enable: true,
          ...DEFAULT_TASK_CONFIG,
          ...taskConfig,
          run_param:
            taskConfig.execution_method === TaskType.AGENT
              ? taskConfig.run_param
              : runParam,
          update_time: Date.now() / 1000,
        })
        onBack()
      } else {
        const res = await chatAPI.Task.add({
          flow_id: agentId,
          conversation_id: conversationId,
          preset_enable: true,
          ...DEFAULT_TASK_CONFIG,
          ...taskConfig,
          run_param:
            taskConfig.execution_method === TaskType.AGENT
              ? taskConfig.run_param
              : runParam,
          update_time: Date.now() / 1000,
        })

        newTaskId = res.data

        const listDetail = await chatAPI.Task.getListDetail(
          newTaskId,
          conversationId,
          agentId,
        )

        onUpdate(listDetail?.data)
      }

      resetConfig()
      onUpdateList(true)
      window.dispatchEvent(new Event('taskListUpdate'))
    } catch (err) {
      console.log(err)
    }
  })

  useEffect(() => {
    if (!task?.task_id) {
      resetConfig()
    } else {
      chatAPI.Task.getDetail({
        taskId: task.task_id,
        conversationId,
        agentId,
      }).then(res => {
        resetConfig(res?.data?.config)
      })
    }
  }, [task?.task_id, conversationId])

  return (
    <div className='w-full flex flex-col h-full overflow-hidden'>
      <div className='flex items-center justify-center h-[64px] px-[16px] flex-none'>
        <div
          className='inline-flex items-center justify-center cursor-pointer w-[24px] h-[24px] hover:bg-bg_3 hover:bg-opacity-[0.08] rounded-[6px] mr-6px'
          onClick={onBack}
        >
          <Icon path='back-black.svg' className='w-[16px]' />
        </div>

        <span className='font-[600] mr-auto text-[16px] ml-[4px]'>
          {task?.task_id ? '查看任务' : '创建任务'}
        </span>

        <div
          className='inline-flex items-center justify-center cursor-pointer w-[24px] h-[24px] hover:bg-bg_3 hover:bg-opacity-[0.08] rounded-[6px]'
          onClick={onClose}
        >
          <Icon path='close.svg' className='w-[16px]' />
        </div>
      </div>

      <div
        className='overflow-auto px-[20px] relative flex-1'
        // element='div'
        // options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
        // defer
      >
        {disabled && (
          <div className='absolute inset-0 z-40 cursor-not-allowed'></div>
        )}
        <div className='border-[1px] border-line p-[16px] rounded-[8px] border-opacity-[0.6]'>
          <TaskSetting
            agentId={agentId}
            versionId={versionId}
            size='small'
            noFlowContent={
              <Empty
                className='ant-empty-small'
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description='当前Agent无可用工作流'
              />
            }
            noPluginContent={
              <Empty
                className='ant-empty-small'
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description='当前Agent无可用插件'
              />
            }
          />
        </div>

        <TaskInput
          onFileUpload={onFileUpload}
          className='mt-[16px] border-[1px] p-[16px] rounded-[8px] border-line border-opacity-[0.6]'
          // disabled={disabled}
        />
      </div>

      {!disabled && (
        <div className='mx-[20px] my-[16px] flex gap-[12px]'>
          <Button className='!font-normal basis-[80px]' onClick={onBack}>
            取消
          </Button>
          <Button
            className='!font-normal flex-1'
            type='primary'
            onClick={handleConfirm}
          >
            完成
          </Button>
        </div>
      )}
    </div>
  )
})
