import { MarkdownVideo } from './MarkdownVideo'
import { MarkdownImage } from './MarkdownImage'

type MarkdownAnchorProps = React.PropsWithChildren<{
  href?: string
  imagePreview?: boolean
  onClick?: (href: string) => void
}>

export const VIDEO_EXTENSIONS = ['mp4', 'webm', 'ogg']
export const IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif', 'bmp']
export function MarkdownAnchor({
  href,
  onClick,
  imagePreview = true,
  children,
}: MarkdownAnchorProps) {
  if (VIDEO_EXTENSIONS.some(ext => href?.endsWith(ext))) {
    return <MarkdownVideo src={href!} />
  }
  if (IMAGE_EXTENSIONS.some(ext => href?.endsWith(ext))) {
    return <MarkdownImage src={href || ''} alt={href} preview={imagePreview} />
  }
  return (
    <a
      className='ui-font-medium ui-text-[14px] ui-text-yeah-primary ui-underline ui-underline-offset-2'
      href={href}
      target='_blank'
      rel='noreferrer'
      onClick={event => {
        event.preventDefault()
        event.stopPropagation()
        if (onClick && href) {
          onClick(href)
        } else {
          window.open(href)
        }
      }}
    >
      {children}
    </a>
  )
}
