import ArmsRum from '@arms/rum-browser';
class Monitor {
    static instance;
    static getInstance() {
        if (!this.instance) {
            this.instance = new Monitor();
        }
        return this.instance;
    }
    logger = ArmsRum;
    init(config) {
        const env = config.env ?? 'local';
        this.logger.init({
            pid: 'ce0ak81asd@62706c656a7f1a1',
            endpoint: 'https://ce0ak81asd-default-cn.rum.aliyuncs.com',
            env: 'local',
            collectors: {
                perf: ['prod'].includes(env),
                webvitals: ['prod'].includes(env),
                staticResource: ['prod'].includes(env),
                action: ['prod'].includes(env),
                api: ['prod', 'pre', 'daily'].includes(env),
                consoleError: ['prod', 'pre', 'daily'].includes(env),
                jsError: ['prod', 'pre', 'daily'].includes(env),
            },
            reportConfig: {
                flushTime: 3000,
                maxEventCount: 20,
            },
            tracing: {
                enable: true,
                sample: 100,
                tracestate: true,
                allowedUrls: [
                    {
                        match: (url) => {
                            return (url.includes('betteryeah.com') || url.includes('bantouyan.com'));
                        },
                        propagatorTypes: ['tracecontext'],
                    },
                ],
                baggage: false,
            },
            ...config,
        });
        window.monitor = this;
    }
    setUser(id) {
        if (!id || !this.logger)
            return;
        const config = this.logger.getConfig();
        this.logger.setConfig({
            ...config,
            user: {
                ...config.user,
                name: String(id),
            },
        });
    }
    reportError(error) {
        if (!error || !this.logger)
            return;
        this.logger.sendException({
            name: error.message,
            message: error.message,
            stack: error.stack,
        });
    }
}
export const monitor = Monitor.getInstance();
