export const DEFAULT_OVERLAY_SCROLLBAR_OPTIONS = {
  overflow: {
    x: 'hidden',
    y: 'scroll',
  },
  scrollbars: {
    autoHide: 'leave',
    autoHideDelay: 1000,
  },
} as any

/**
 * 用于Antd组件的高度的常量
 */
export const Size = {
  small: '32px',
  middle: '36px',
  large: '40px',
  undefined: '36px',
}

/**
 * 用于Antd组件的文本大小的常量
 */
export const TextSize = {
  small: '12px',
  middle: '14px',
  large: '14px',
  undefined: '14px',
}
