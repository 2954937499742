import { ChatApi } from '@bty/chat-fetch'
import { useMemo } from 'react'
import { useBusinessContext } from './useBusinessContext'

export function useChatAPI() {
  const { env, auth } = useBusinessContext()
  const chatAPI = useMemo(
    () =>
      new ChatApi({
        authorization: auth.token || '',
        workspaceId: auth.workspaceId || '',
        robotId: '',
        env,
        clientType: 'PC',
      }),
    [auth, env],
  )

  return {
    chatAPI,
  }
}
