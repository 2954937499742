import { useMemo, useRef, useState } from 'react'
import { ReactComponent as PlayIcon } from '../../../../assets/svg/play.svg'

function safeDecodeURIComponent(uri: string) {
  try {
    return decodeURIComponent(uri)
  } catch (e) {
    return uri
  }
}
export function MarkdownVideo({ src }: { src: string }) {
  const url = useMemo(() => safeDecodeURIComponent(src), [src])
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [isPlayed, setIsPlayed] = useState(false)

  const handlePlay = () => {
    setIsPlayed(true)
    videoRef.current?.play()
  }

  return (
    <div className='ui-relative ui-w-fit ui-h-fit'>
      {!isPlayed && (
        <div className='ui-absolute ui-z-[2] ui-w-full ui-h-full ui-flex ui-items-center ui-justify-center'>
          <div
            onClick={handlePlay}
            className='ui-rounded-[12px] ui-w-[44px] ui-h-[44px] ui-bg-[#000]/[0.6] ui-border-[#E1E1E5] ui-border-opacity-60 ui-border-[1px] ui-flex ui-items-center ui-justify-center ui-cursor-pointer'
          >
            <PlayIcon className='ui-text-[#fff] ui-w-[17px] ui-ml-[2px]' />
          </div>
        </div>
      )}
      <video
        ref={videoRef}
        className='ui-max-w-[260px] ui-max-h-[461px] ui-p-0 ui-m-0 ui-rounded-[12px]'
        src={url}
        controls={isPlayed}
      />
    </div>
  )
}
