import { memo, useEffect, useRef, useState } from 'react'
import { useMemoizedFn, useRequest } from 'ahooks'
import type { TaskListResult } from '@bty/chat-fetch'
import { ConfigProvider } from 'antd'
import type { ChatProps } from '@bty/chat-ui'
import { ANTD_TOKEN } from '@bty/ai-shared'
import { useChatAPI } from '../../hooks/useChatAPI'
import { cn } from '../../utils/cn'
import { TaskList } from './TaskList'
import { TaskLog } from './TaskLog'
import { TaskDetail } from './TaskDetail'
import { TaskProvider } from './TaskContext'

interface TaskInfoProps {
  agentId: string
  versionId: string
  showTaskId?: string
  conversationId: string
  layout?: 'desktop' | 'mobile'
  onFileUpload?: ChatProps['onFileUpload']
  // getConversationId: () => string
  onBack?: () => void
  onClose?: () => void
}

export const TaskInfo = memo((props: TaskInfoProps) => {
  const {
    agentId,
    versionId,
    showTaskId,
    conversationId,
    layout = 'desktop',
    onFileUpload,
    onBack,
    onClose = () => {},
  } = props

  const { chatAPI } = useChatAPI()
  const {
    data: taskList,
    run: refreshList,
    runAsync: getList,
    loading: taskListLoading,
    mutate,
  } = useRequest(
    async () => {
      const res = await chatAPI.Task.getList(agentId, conversationId)

      return res?.data
    },
    {
      ready: !!agentId && !!conversationId,
      refreshDeps: [agentId, conversationId],
    },
  )

  const historyList = useRef<string[]>([])
  const [tab, setTab] = useState(showTaskId ? 'log' : 'list')
  const [showTask, setShowTask] = useState<TaskListResult>()
  const [isDelete, setIsDelete] = useState(false)

  const handleBack = useMemoizedFn(() => {
    onBack?.()
    setTab(historyList.current.pop() ?? 'list')
  })

  const handleToEdit = useMemoizedFn((task?: TaskListResult) => {
    console.log('task', task)
    setTab('edit')
    historyList.current.push(tab)
    setShowTask(task)
  })

  const handleToLog = useMemoizedFn((task: TaskListResult, saveNow = true) => {
    setTab('log')
    if (saveNow) {
      historyList.current.push(tab)
    }
    setShowTask(task)
  })

  const handleUpdateList = useMemoizedFn(
    (refresh?: boolean, task?: TaskListResult) => {
      if (refresh) {
        refreshList()
        return
      }

      if (task?.task_id === showTask?.task_id) {
        setShowTask(task)
      }

      if (task) {
        mutate(prev => {
          if (!prev) return []
          return prev.map(item => {
            if (item.task_id === task.task_id) {
              return task
            }
            return item
          })
        })
      }
    },
  )

  const handleInitTaskId = useMemoizedFn(async () => {
    let list = taskList
    if (!list?.length) {
      list = await getList()
    }

    setTab('log')
    const _showTask = list?.find(item => item.task_id === showTaskId)
    setShowTask(_showTask)
    if (!_showTask) {
      setIsDelete(true)
    }
  })

  useEffect(() => {
    if (!showTaskId) {
      setTab('list')
      return
    }
    handleInitTaskId()
  }, [showTaskId])

  useEffect(() => {
    const handle = () => {
      handleUpdateList(true)
    }

    window.addEventListener('chatMessageUpdate', handle)
    return () => {
      window.removeEventListener('chatMessageUpdate', handle)
    }
  }, [])

  return (
    <ConfigProvider
      theme={{
        token: ANTD_TOKEN,
        components: {
          Input: {
            activeBorderColor: '#7B61FF',
            hoverBg: '#fff',
          },
        },
      }}
    >
      <div
        className={cn('h-full w-full overflow-hidden bg-[white] bg-opacity-90')}
      >
        <div
          className={cn('h-full', {
            hidden: tab !== 'list',
          })}
        >
          <TaskList
            list={taskList ?? []}
            agentId={agentId}
            conversationId={conversationId}
            toEdit={handleToEdit}
            layout={layout}
            toLog={handleToLog}
            loading={taskListLoading}
            onClose={onClose}
            onUpdateList={handleUpdateList}
          />
        </div>
        {tab === 'log' && (
          <TaskLog
            agentId={agentId}
            isDelete={isDelete}
            taskId={showTask?.task_id!}
            conversationId={conversationId}
            onUpdateList={handleUpdateList}
            toEdit={handleToEdit}
            onBack={handleBack}
            onClose={onClose}
          />
        )}
        {tab === 'edit' && (
          <TaskProvider>
            <TaskDetail
              agentId={agentId}
              versionId={versionId}
              task={showTask}
              conversationId={conversationId}
              onUpdateList={handleUpdateList}
              onFileUpload={onFileUpload}
              onUpdate={task => handleToLog(task, false)}
              onBack={handleBack}
              onClose={onClose}
            />
          </TaskProvider>
        )}
      </div>
    </ConfigProvider>
  )
})
