import type { BaseBusinessType } from '../types/base'
import { FetchRequest } from './lib/lib'

export interface STSTokenResponse {
  access_key_id: string
  access_key_secret: string
  security_token: string
  expiration: string
}
export function getSTSToken({ env, auth }: BaseBusinessType) {
  const request = new FetchRequest(auth, env)

  return request.get('/v1/oss_key')
}
