import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PureComponent } from 'react';
import { monitor } from '../Monitor';
export class ErrorBoundary extends PureComponent {
    state = {
        error: undefined,
        hasError: false,
    };
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: undefined };
    }
    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }
    componentDidCatch(error) {
        monitor.reportError(error);
    }
    handleReload = () => {
        this.setState({ hasError: false, error: null });
    };
    render() {
        const { fallback, children } = this.props;
        const { error, hasError } = this.state;
        if (hasError) {
            return (fallback ?? (_jsxs("div", { children: [_jsx("h1", { children: error?.message }), _jsx("pre", { children: error?.stack }), _jsx("button", { onClick: this.handleReload, children: "Try Again" })] })));
        }
        return children;
    }
}
