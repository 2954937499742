import type { ColumnReqType, ColumnType } from './api'

export enum UITypes {
  ID = 'ID',
  SingleLineText = 'SingleLineText',
  LongText = 'LongText',
  Attachment = 'Attachment',
  Checkbox = 'Checkbox',
  MultiSelect = 'MultiSelect',
  SingleSelect = 'SingleSelect',
  Date = 'Date',
  Year = 'Year',
  Time = 'Time',
  PhoneNumber = 'PhoneNumber',
  Email = 'Email',
  URL = 'URL',
  Number = 'Number',
  Decimal = 'Decimal',
  DateTime = 'DateTime',
  JSON = 'JSON'
}

export const UITypesName = {
  [UITypes.ID]: 'ID',
  [UITypes.SingleLineText]: 'Single line text',
  [UITypes.LongText]: 'Long text',
  RichText: 'Rich text',
  [UITypes.Attachment]: 'Attachment',
  [UITypes.Checkbox]: 'Checkbox',
  [UITypes.MultiSelect]: 'Multi select',
  [UITypes.SingleSelect]: 'Single select',
  [UITypes.Date]: 'Date',
  [UITypes.Year]: 'Year',
  [UITypes.Time]: 'Time',
  [UITypes.PhoneNumber]: 'Phone number',
  [UITypes.Email]: 'Email',
  [UITypes.URL]: 'URL',
  [UITypes.Number]: 'Number',
  [UITypes.Decimal]: 'Decimal',
  [UITypes.DateTime]: 'Date time',
  [UITypes.JSON]: 'JSON',
  CreatedTime: 'Created time',
  LastModifiedTime: 'Last modified time'
}

export const numericUITypes = [UITypes.Number, UITypes.Decimal, UITypes.Year]

export function isNumericCol(
  col: UITypes | { readonly uidt: UITypes | string } | ColumnReqType | ColumnType
) {
  return numericUITypes.includes(<UITypes>(typeof col === 'object' ? col?.uidt : col))
}
