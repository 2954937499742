// src/provider.tsx
import { createContext, useMemo } from "react";
import { RouterProvider } from "react-router-dom";
import { jsx, jsxs } from "react/jsx-runtime";
var AuthContext = createContext({
  access: {},
  state: {},
  loaded: false
});
function AuthRouterProvider({
  children,
  state,
  authFn,
  login,
  logout,
  loaded = true,
  router,
  initStateAction
}) {
  const value = useMemo(() => {
    return {
      access: authFn(state),
      state: state || {},
      loaded,
      initStateAction
    };
  }, [state, authFn, login, logout, loaded, initStateAction]);
  return /* @__PURE__ */ jsxs(AuthContext.Provider, { value, children: [
    /* @__PURE__ */ jsx(RouterProvider, { router }),
    children
  ] });
}

// src/createRouter.tsx
import { createBrowserRouter } from "react-router-dom";

// src/authComponent.tsx
import { Navigate, useParams } from "react-router-dom";
import {
  Children,
  cloneElement,
  useEffect,
  useRef,
  useState
} from "react";

// src/useAuth.ts
import { useContext } from "react";
function useAuth() {
  return useContext(AuthContext);
}

// src/authComponent.tsx
import { Fragment, jsx as jsx2, jsxs as jsxs2 } from "react/jsx-runtime";
function AuthLayout({
  auth,
  children,
  redirect,
  defaultRedirectPath,
  loadComponent
}) {
  const { access, loaded, initStateAction } = useAuth();
  const params = useParams();
  const initRef = useRef(false);
  const [initialized, setInitialized] = useState(false);
  const [redirectURL, setRedirectURL] = useState("");
  const init = async () => {
    if (!initRef.current) {
      const [flag, url] = await (initStateAction == null ? void 0 : initStateAction(params));
      setInitialized(flag);
      url && setRedirectURL(url);
      initRef.current = true;
    }
  };
  useEffect(() => {
    if (!loaded) {
      init();
    }
  }, [loaded]);
  if (!loaded) {
    return loadComponent ? loadComponent() : null;
  }
  if (!initialized && redirectURL) {
    return /* @__PURE__ */ jsx2(Navigate, { to: redirectURL, replace: true });
  }
  const isAuth = typeof auth === "function" ? auth(access) : Array.isArray(auth) ? auth.every((a) => access[a]) : access[auth];
  if (!isAuth) {
    const path = typeof redirect === "function" ? redirect() : redirect;
    return /* @__PURE__ */ jsx2(
      Navigate,
      {
        replace: true,
        to: path || `${defaultRedirectPath || "/login"}?redirect=${encodeURIComponent(
          window.location.pathname
        )}`
      }
    );
  }
  return /* @__PURE__ */ jsxs2(Fragment, { children: [
    children,
    " "
  ] });
}
function Access({
  children,
  access,
  hide = false,
  fallback,
  callback
}) {
  if (!access) {
    return !hide ? /* @__PURE__ */ jsx2(Fragment, { children: Children.map(children, (child, _index) => {
      if (child) {
        return cloneElement(child, {
          onClick: () => {
            callback == null ? void 0 : callback();
          }
        });
      }
    }) }) : /* @__PURE__ */ jsx2(Fragment, { children: fallback });
  }
  return /* @__PURE__ */ jsxs2(Fragment, { children: [
    children,
    " "
  ] });
}

// src/createRouter.tsx
import { jsx as jsx3 } from "react/jsx-runtime";
function generateAuthRoute(routes, defaultRedirectPath, loadComponent, Provider) {
  return routes.map((route) => {
    const {
      element,
      auth,
      children,
      redirect,
      loadComponent: routeLoadComponent
    } = route;
    const _element = Provider ? /* @__PURE__ */ jsx3(Provider, { children: element }) : element;
    return {
      ...route,
      element: _element ? auth ? /* @__PURE__ */ jsx3(
        AuthLayout,
        {
          auth,
          redirect,
          defaultRedirectPath,
          loadComponent: routeLoadComponent || loadComponent,
          children: _element
        }
      ) : _element : void 0,
      children: children ? generateAuthRoute(children) : void 0
    };
  });
}
function createAuthBrowserRouter(routes, opts) {
  const {
    auth: _,
    defaultRedirectPath,
    loadComponent,
    provider,
    ...restOpts
  } = opts || {};
  const authRoutes = generateAuthRoute(
    routes,
    defaultRedirectPath,
    loadComponent,
    provider
  );
  const router = createBrowserRouter(authRoutes, restOpts);
  return router;
}
export {
  Access,
  AuthRouterProvider,
  createAuthBrowserRouter,
  useAuth
};
