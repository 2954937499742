import { memo, useState } from 'react'
import { Dropdown, message, Switch } from 'antd'
import { useMemoizedFn } from 'ahooks'
import dayjs from 'dayjs'

// import { DeleteConfirm } from '@/components/DeleteConfirm'
import type { TaskListResult } from '@bty/chat-fetch'
import { cn } from '../../utils/cn'
import { useChatAPI } from '../../hooks/useChatAPI'
import { Icon } from '../base/Icon'
import { DeleteConfirm } from '../base/DeleteConfirm'
import { PanelMenu, PresentPanelMenu, TaskStatusMap } from './const'

interface TaskListItemProps {
  task: TaskListResult
  readonly?: boolean
  conversationId: string
  onEdit?: (task?: TaskListResult) => void
  onSelect?: (task: TaskListResult) => void
  onDelete?: (task: TaskListResult) => void
  onUpdateList?: (refresh?: boolean, task?: TaskListResult) => void
  className?: string
}

export const TaskListItem = memo((props: TaskListItemProps) => {
  const {
    task,
    readonly,
    conversationId,
    onEdit,
    onSelect,
    onDelete,
    onUpdateList,

    className,
  } = props

  const [showConfirm, setShowConfirm] = useState(false)

  const { chatAPI } = useChatAPI()

  const handleOpenChange = useMemoizedFn((open: boolean) => {
    if (!open) {
      setShowConfirm(false)
    }
  })

  const handleEdit = useMemoizedFn(async (info: any) => {
    if (info.key === 'detail') {
      onEdit?.(task)
    }

    if (info.key === 'edit') {
      onEdit?.(task)
    }

    if (info.key === 'remove') {
      setShowConfirm(true)
    }
  })

  const handleDetail = useMemoizedFn(() => {
    onSelect?.(task)
  })

  const handleEnable = useMemoizedFn(async (enable: boolean) => {
    onUpdateList?.(false, { ...task, is_enable: enable })
    await chatAPI.Task.enable(task, conversationId, enable)
    window.dispatchEvent(new Event('taskListUpdate'))
  })

  const handleDelete = useMemoizedFn(async () => {
    await chatAPI.Task.delete(task.task_id, conversationId)
    message.success('删除成功')
    onUpdateList?.(true)
    onDelete?.(task)
    window.dispatchEvent(new Event('taskListUpdate'))
  })

  const handleExecute = useMemoizedFn(async () => {
    await chatAPI.Task.execute(task.id)
    message.success('任务触发成功')
  })

  return (
    <div
      className={cn(
        'border-[1px] border-line rounded-[8px] p-[16px] mb-[10px] cursor-pointer',
        className,
      )}
      onClick={handleDetail}
    >
      <div className='flex  mb-[8px] items-center justify-center h-[24px]'>
        <div className='mr-auto flex items-center justify-center overflow-hidden'>
          <span className='mr-auto text-[#17171D] text-[16px] font-[500] flex-auto truncate'>
            {task.task_name}
          </span>

          {task.is_preset && (
            <span className='ml-[8px] text-[14px] bg-[#E9E7FF] rounded-[4px] leading-[18px] px-[4px] text-[#7164FF] text-nowrap'>
              预设
            </span>
          )}
        </div>

        {!readonly && task.is_enable && (
          <span onClick={e => e.stopPropagation()}>
            <button
              className='inline-flex items-center justify-center cursor-pointer w-[24px] h-[24px] hover:bg-bg_3 hover:bg-opacity-[0.08] rounded-[6px] bg-transparent  ml-[8px]'
              onClick={handleExecute}
            >
              <Icon path='run.svg' className='w-[16px]' />
            </button>
          </span>
        )}

        {!readonly && (
          <span
            className='ml-[8px] h-[24px] flex items-center justify-center'
            onClick={e => e.stopPropagation()}
          >
            <Switch
              size='small'
              className=''
              checked={task.is_enable}
              onChange={handleEnable}
            />
          </span>
        )}

        {!readonly && task.is_preset && (
          <div className='ml-[8px]' onClick={e => e.stopPropagation()}>
            <Dropdown
              overlayClassName='[&_.ant-dropdown-menu]:!p-[4px]'
              menu={{
                items: PresentPanelMenu,
                onClick: handleEdit,
              }}
              placement='bottomRight'
            >
              <button
                className='inline-flex items-center justify-center cursor-pointer w-[24px] h-[24px] hover:bg-bg_3 hover:bg-opacity-[0.08] rounded-[6px] bg-transparent  text-[rgba(98,105,153,0.6)] [&.ant-dropdown-open]:bg-bg_3 [&.ant-dropdown-open]:bg-opacity-[0.06]'
                onClick={e => e.stopPropagation()}
              >
                <Icon path='more.svg' />
              </button>
            </Dropdown>
          </div>
        )}

        {!readonly && !task.is_preset && (
          <div className='ml-[8px]' onClick={e => e.stopPropagation()}>
            <DeleteConfirm
              rootClassName='w-[360px] [&_.ant-popover-inner]:!p-[20px]'
              placement='bottomRight'
              align={{ offset: [8, 8] }}
              message='确认删除任务？删除后不再执行此任务'
              open={showConfirm}
              onOpenChange={handleOpenChange}
              onDelete={handleDelete}
            >
              <Dropdown
                overlayClassName='[&_.ant-dropdown-menu]:!p-[4px]'
                menu={{
                  items: PanelMenu,
                  onClick: handleEdit,
                }}
                placement='bottomRight'
              >
                <button
                  className='inline-flex items-center justify-center cursor-pointer w-[24px] h-[24px] hover:bg-bg_3 hover:bg-opacity-[0.08] rounded-[6px] text-[rgba(98,105,153,0.6)] [&.ant-dropdown-open]:bg-bg_3 [&.ant-dropdown-open]:bg-opacity-5'
                  onClick={e => e.stopPropagation()}
                >
                  <Icon path='more.svg' />
                </button>
              </Dropdown>
            </DeleteConfirm>
          </div>
        )}
      </div>

      <div className='flex gap-[8px] text-[14px]'>
        {task.remark && (
          <span className='bg-[#E1EFFF] rounded-[4px]  px-[4px] text-[#5689FF] text-nowrap'>
            {task.remark}
          </span>
        )}

        <span className='text-nowrap truncate'>
          {task.last_task_time && (
            <span className='mr-[8px] leading-[18px] text-[#8D8D99]'>
              {dayjs(
                typeof task.last_task_time === 'string'
                  ? task.last_task_time
                  : task.last_task_time * 1000,
              ).format('MM-DD HH:mm')}
            </span>
          )}

          {task.last_task_status && (
            <span className='leading-[18px] text-[#8D8D99]'>
              {TaskStatusMap[task.last_task_status].text}
            </span>
          )}
        </span>
      </div>
    </div>
  )
})
