import type { PopconfirmProps } from 'antd'
import { Popconfirm } from 'antd'
import type { ReactNode } from 'react'

interface DeleteConfirmProps extends Omit<PopconfirmProps, 'title'> {
  className?: string
  message: string
  cancelText?: string
  deleteText?: string
  onCancel?: () => void
  onDelete?: () => void
  children: ReactNode
}

export function DeleteConfirm(props: DeleteConfirmProps) {
  const {
    message,
    cancelText = '取消',
    deleteText = '确认',
    onCancel,
    onDelete,
    children,
    ...rest
  } = props

  return (
    <Popconfirm
      {...rest}
      icon={null}
      title={null}
      description={<div className='mb-24px'>{message}</div>}
      cancelText={cancelText}
      cancelButtonProps={{
        size: 'middle',
      }}
      okText={deleteText}
      okButtonProps={{
        size: 'middle',
        danger: true,
      }}
      onCancel={onCancel}
      onConfirm={onDelete}
    >
      {children}
    </Popconfirm>
  )
}
