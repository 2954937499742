import type { BusinessAuth } from '../../types/auth'
import type { APIEnv } from '../../types/base'
import { API_URL } from './const'

export class FetchRequest {
  private prefix: string
  constructor(private auth: BusinessAuth, private env: APIEnv) {
    this.prefix = API_URL[this.env]
  }

  public async get<T = any>(url: string, params?: Record<string, any>, headers: Record<string, any> = {}) {
    const { token }  = this.auth
    const queryString = Object.entries(params || {}).map(([key, value]) => `${key}=${value}`).join('&')

    const res = await fetch(`${this.prefix}${url}${queryString ? `?${queryString}` : ''}`, {
      method: 'GET', // 请求方法
      headers: {
        // 'application-id': applicationId || '',
        authorization: token,
        // 'Workspace-Id': workspaceId || '',
        'Content-Type': 'application/json',
        ...headers
      },
    })

    const data = await res.json()
    return data as T

  }

  public async post<T = any>(url: string, body?: Record<string, any>, headers: Record<string, any> = {}) {

    const { token }  = this.auth
    const res = await fetch(`${this.prefix}${url}`, {
      method: 'POST', // 请求方法
      headers: {
        // 'application-id': applicationId || '',
        authorization: token,
        // 'Workspace-Id': workspaceId || '',
        'Content-Type': 'application/json',
        ...headers
      },
      body: JSON.stringify(body || {}),
    })

    const data = await res.json()
    return data as T

  }

  public async put<T = any>(url: string, body?: Record<string, any>, headers: Record<string, any> = {}) {

    const { token }  = this.auth
    const res = await fetch(`${this.prefix}${url}`, {
      method: 'PUT', // 请求方法
      headers: {
        // 'application-id': applicationId || '',
        authorization: token,
        // 'Workspace-Id': workspaceId || '',
        'Content-Type': 'application/json',
        ...headers
      },
      body: JSON.stringify(body || {}),
    })

    const data = await res.json()
    return data as T

  }
}