import { Upload } from 'antd'
import React from 'react'
import OSS from 'ali-oss'
import type { UploadProps } from 'antd/es/upload/interface'
import { config } from '../../env.config'
import { getSTSToken } from '../../apis/oss'
import { useBusinessContext } from '../../hooks/useBusinessContext'

export function BusinessUpload(
  props: Omit<UploadProps, 'customRequest' | 'onChange'> & {
    onChange?: (url: string) => void
    children?: React.ReactNode
    path?: string
  },
) {
  const { env, auth } = useBusinessContext()
  const { onChange, children, path = '/ai/upload', ...resProps } = props
  return (
    <Upload
      {...resProps}
      customRequest={async opt => {
        const file = opt.file as File
        try {
          const res = await getSTSToken({ env, auth })
          const token = res.data
          if (token) {
            const ossClient = new OSS({
              region: 'oss-cn-hangzhou',
              accessKeyId: token.access_key_id,
              accessKeySecret: token.access_key_secret,
              stsToken: token.security_token,
              bucket: config.AI_DATASTORE_RESOURCE_BUCKET[env],
            })
            const response = await ossClient.multipartUpload(
              `${path}/${new Date().getTime()}${file?.name}`,
              file,
              {},
            )
            const url = (
              (response.res as any).requestUrls as string[] | undefined
            )?.[0]?.split('?')[0]
            onChange?.(url?.replace('http://', 'https://') || '')
          }
        } catch (_) {}
      }}
    >
      {children}
    </Upload>
  )
}
