export interface AgentCardProps {
  avatar: string
  name: string
  description: string
  developer?: string
  workspaceName?: string
  labels: string[]
  hot?: number
  id: string
}

export function AgentCard(
  props: AgentCardProps & { onClick?: (id: string) => void },
) {
  const { avatar, description, developer, workspaceName, hot, name, id } = props
  return (
    <div
      className='flex gap-[12px] w-full h-[102px] bg-[white] rounded-[12px] border border-solid border-[transparent] hover:border-[#7B61FF]  p-[16px] overflow-hidden cursor-pointer'
      onClick={() => props?.onClick?.(id)}
    >
      <img src={avatar} className='w-[70px] h-[70px] rounded-full' />
      <div className='flex flex-col items-start justify-between  w-full overflow-hidden'>
        <div className='text-[14px] font-bold  w-full truncate'>{name}</div>
        <p className='text-[#8D8D99] text-[12px]  w-full truncate'>
          {description}
        </p>
        {developer && (
          <p className='text-[#8D8D99] text-[12px]  w-full truncate'>
            开发者：{developer} ｜ 空间：{workspaceName}
          </p>
        )}
        {Boolean(hot && hot > 0) && (
          <div className='flex items-center '>
            <img
              src='https://resource.bantouyan.com/betteryeah/business-comonents/hot.png'
              className='w-[10px]'
            />
            <div className='ml-[6px] text-[12px] text-[#8D8D99]'>{hot}</div>
          </div>
        )}
      </div>
    </div>
  )
}
