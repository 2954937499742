import type { ReactNode } from 'react'
import { memo, useEffect, useMemo } from 'react'
import { Form, Radio } from 'antd'
import { useMemoizedFn, useRequest } from 'ahooks'
import { Select, Input, TextArea } from '@bty/components'
import { cn } from '../../utils/cn'
import { useChatAPI } from '../../hooks/useChatAPI'
import { AppLogo } from '../base/AppLogo'

import {
  CustomLoopOption,
  CustomLoopType,
  TaskOption,
  ExecuteType,
  TaskType,
  HourOption,
  LoopOption,
  LoopType,
  MinuteOption,
  ExecuteOption,
} from './const'
import { useTaskData } from './TaskContext'
import { WeekSelectString } from './WeekSelect'
import { DateSelect } from './DateSelect'

interface TaskSettingProps {
  agentId: string
  versionId: string
  className?: string
  title?: string
  size?: 'normal' | 'small'
  disabled?: boolean
  noFlowContent?: ReactNode
  noPluginContent?: ReactNode
}

export const TaskSetting = memo((props: TaskSettingProps) => {
  const {
    agentId,
    // versionId,
    className,
    title,
    size,
    disabled,
    noFlowContent,
    noPluginContent,
  } = props

  const { flowListRef, pluginListRef, form } = useTaskData()

  const type = Form.useWatch('execution_method', form)
  const timeType = Form.useWatch('execution_type', form)
  const loopType = Form.useWatch('task_type', form)
  const customType = Form.useWatch('custom_task_type', form)

  const { chatAPI } = useChatAPI()

  // const { data } = useRequest(getAgentShortcutsList, {
  //   ready: !!agentId && !!versionId,
  //   defaultParams: [agentId, versionId],
  //   refreshDeps: [agentId, versionId],
  // })

  const { data: agentDetail } = useRequest(
    id => chatAPI.Agent.queryAgentById(id),
    {
      ready: !!agentId,
      defaultParams: [agentId],
      refreshDeps: [agentId],
    },
  )

  const flowList = useMemo(() => {
    const list = agentDetail?.data?.flows?.filter((e: any) => e.is_enable) ?? []
    flowListRef.current = list
    return list
  }, [agentDetail])

  const pluginList = useMemo(() => {
    const list =
      agentDetail?.data?.utility?.filter((e: any) => e.is_enable) ?? []
    pluginListRef.current = list
    return list
  }, [agentDetail])

  const handleCustomTypeChange = useMemoizedFn(() => {
    form.setFieldValue('custom_task_config', undefined)
  })

  const handleChangeTaskType = useMemoizedFn(() => {
    form.setFieldValue('rel_flow_plugin_id', undefined)
  })

  const flowOptions = useMemo(() => {
    return flowList.map((item: any) => ({
      label: (
        <div className='flex items-center gap-[8px]'>
          <AppLogo
            className='rounded-[4px]'
            value={item.icon}
            type={item.icon?.startsWith('http') ? 'image' : 'emoji'}
            color={item.color}
            size={24}
            fillSize={14}
          />
          <div>{item.name}</div>
        </div>
      ),
      value: item.flow_id,
      data: item,
    }))
  }, [flowList])

  const pluginOptions = useMemo(() => {
    return pluginList.map((item: any) => ({
      label: (
        <div className='flex items-center gap-[8px]'>
          <AppLogo
            className='rounded-[4px]'
            imageClassName='!rounded-[4px]'
            value={item.metadata?.icon ?? ''}
            type={item.metadata?.icon?.startsWith('http') ? 'image' : 'emoji'}
            color={item.metadata?.color}
            size={24}
            fillSize={14}
          />
          <div>{item.display_name ?? item.name}</div>
        </div>
      ),
      value: item.function_id,
      data: item,
    }))
  }, [pluginList])

  const agentOptions = useMemo(() => {
    return [
      {
        label: (
          <div className='flex items-center gap-[8px]'>
            <AppLogo
              className='rounded-[4px]'
              imageClassName='rounded-[4px]!'
              value={agentDetail?.data?.application?.icon ?? ''}
              type={
                agentDetail?.data?.application?.icon?.startsWith('http')
                  ? 'image'
                  : 'emoji'
              }
              color={agentDetail?.data?.application?.color}
              size={24}
              fillSize={14}
            />
            <div>{agentDetail?.data?.application?.name}</div>
          </div>
        ),
        value: agentDetail?.data?.appId,
      },
    ]
  }, [agentId, agentDetail])

  useEffect(() => {
    if (type === TaskType.AGENT && agentId) {
      form.setFieldsValue({
        rel_flow_plugin_id: agentId,
      })
    }
  }, [type, agentId])

  return (
    <Form disabled={disabled} className={className} form={form}>
      {title && <div className='font-[600] mb-[24px] text-[16px]'>{title}</div>}

      <div>
        <div className='mb-[8px]'>
          <span>执行方式</span>
          <span className='text-[#FF512B] ml-[4px]'>*</span>
        </div>

        <div className='flex'>
          <Form.Item
            name='execution_method'
            initialValue={TaskType.PLUGIN}
            required
            noStyle
          >
            <Select
              className='flex-1 h-[36px]'
              options={TaskOption}
              onChange={handleChangeTaskType}
            />
          </Form.Item>
        </div>

        <div className='flex mt-[8px] flex-col'>
          {type === TaskType.FLOW && (
            <Form.Item
              className='w-full mb-[0px] h-[36px] [&_.ant-form-item-explain-error]:m-0 [&_.ant-form-item-explain]:mt-[4px]'
              name='rel_flow_plugin_id'
              required
              rules={[{ required: true, message: '请选择工作流' }]}
            >
              <Select
                className='flex-1 h-[36px]'
                placeholder='请选择工作流'
                options={flowOptions}
                notFoundContent={noFlowContent}
              />
            </Form.Item>
          )}
          {type === TaskType.PLUGIN && (
            <Form.Item
              className='w-full mb-[0px] h-[36px] [&_.ant-form-item-explain-error]:m-0 [&_.ant-form-item-explain]:mt-[4px]'
              name='rel_flow_plugin_id'
              required
              rules={[{ required: true, message: '请选择插件' }]}
            >
              <Select
                className='flex-1 h-[36px]'
                placeholder='请选择插件'
                options={pluginOptions}
                notFoundContent={noPluginContent}
              />
            </Form.Item>
          )}

          {type === TaskType.AGENT && (
            <Form.Item
              className='w-full mb-[8px] h-[36px] [&_.ant-form-item-explain-error]:m-0 [&_.ant-form-item-explain]:mt-[4px]'
              name='rel_flow_plugin_id'
              hidden
              required
              rules={[{ required: true, message: '请选择Agent' }]}
            >
              <Select
                className='flex-1 h-[36px]'
                placeholder='请选择Agent'
                options={agentOptions}
              />
            </Form.Item>
          )}

          {type === TaskType.AGENT && (
            <Form.Item name={['run_param', 'user_message']} required noStyle>
              <TextArea
                className='flex-1 !mb-[24px]'
                placeholder='输入你的指令，例如：搜索总结科技新闻'
              />
            </Form.Item>
          )}
        </div>
      </div>

      <div className='mb-[24px] last:mb-0'>
        <div className='mb-[8px]'>
          <span>执行时间</span>
          <span className='text-[#FF512B] ml-[4px]'>*</span>
        </div>
        <div className='flex flex-wrap items-center gap-[8px] min-h-[36px]'>
          <Form.Item
            name='execution_type'
            initialValue={ExecuteType.IMMEDIATELY}
            required
            noStyle
          >
            {size !== 'small' && (
              <Radio.Group>
                {ExecuteOption.map(each => (
                  <Radio key={each.value} value={each.value}>
                    {each.label}
                  </Radio>
                ))}
              </Radio.Group>
            )}
            {size === 'small' && (
              <Select className='w-full h-[36px]' options={ExecuteOption} />
            )}
          </Form.Item>

          {timeType === ExecuteType.SCHEDULE && (
            <>
              <Form.Item
                className='!mb-0 h-[36px] [&_.ant-form-item-explain-error]:m-0 [&_.ant-form-item-explain]:mt-[4px]'
                name='task_start_time'
                required
                rules={[{ required: true, message: '请选择执行时间' }]}
              >
                <DateSelect />
              </Form.Item>

              <Form.Item
                name='task_type'
                initialValue={LoopType.NONE}
                required
                noStyle
              >
                <Select
                  className={cn('h-[36px] w-[120px]', {
                    'flex-1': size === 'small',
                  })}
                  options={LoopOption}
                />
              </Form.Item>
            </>
          )}
        </div>
      </div>

      {loopType === LoopType.CUSTOM && (
        <div className='mb-[24px] last:mb-0'>
          <div className='mb-[8px]'>
            <span>自定义重复频率</span>
          </div>
          <div className='flex items-center gap-[8px] h-[36px] mt-[8px]'>
            <Form.Item
              className='!mb-0 w-[120px] h-[36px] [&_.ant-form-item-explain-error]:m-0 [&_.ant-form-item-explain]:mt-[4px]'
              name='custom_task_type'
              initialValue={CustomLoopType.MINUTE}
              required
              rules={[{ required: true, message: '请选择频率' }]}
            >
              <Select
                className='h-[36px]'
                placeholder='请选择'
                options={CustomLoopOption}
                onChange={handleCustomTypeChange}
              />
            </Form.Item>

            {customType === CustomLoopType.MINUTE && (
              <Form.Item
                className='!mb-0 w-full h-[36px] [&_.ant-form-item-explain-error]:m-0 [&_.ant-form-item-explain]:mt-[4px]'
                name='custom_task_config'
                required
                rules={[{ required: true, message: '请选择间隔时间' }]}
              >
                <Select
                  className={cn('h-[36px] flex-1', {
                    'max-w-[216px]': size !== 'small',
                  })}
                  placeholder='请选择'
                  options={MinuteOption}
                />
              </Form.Item>
            )}

            {customType === CustomLoopType.HOUR && (
              <Form.Item
                className='!mb-0 w-full h-[36px] [&_.ant-form-item-explain-error]:m-0 [&_.ant-form-item-explain]:mt-[4px]'
                name='custom_task_config'
                required
                rules={[{ required: true, message: '请选择间隔时间' }]}
              >
                <Select
                  className={cn('h-[36px] flex-1', {
                    'max-w-[216px]': size !== 'small',
                  })}
                  placeholder='请选择'
                  options={HourOption}
                />
              </Form.Item>
            )}

            {customType === CustomLoopType.WEEK && (
              <Form.Item
                className='!mb-0 w-full h-[36px] [&_.ant-form-item-explain-error]:m-0 [&_.ant-form-item-explain]:mt-[4px]'
                name='custom_task_config'
                required
                rules={[{ required: true, message: '请选择间隔时间' }]}
              >
                <WeekSelectString />
              </Form.Item>
            )}
          </div>
        </div>
      )}

      <div className='mb-[24px] last:mb-0'>
        <div className='mb-[8px]'>
          <span>任务名称</span>
          <span className='text-[#FF512B] ml-[4px]'>*</span>
        </div>
        <div>
          <Form.Item
            className='h-[36px] [&_.ant-form-item-explain-error]:m-0 [&_.ant-form-item-explain]:mt-[4px]'
            name='task_name'
            required
            rules={[{ required: true, message: '请输入任务名称' }]}
          >
            <Input placeholder='请输入' />
          </Form.Item>
        </div>
      </div>
    </Form>
  )
})
