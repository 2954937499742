import type { BaseBusinessType } from '../types/base'
import type { ApiResponse } from './lib/types'
import { FetchRequest } from './lib/lib'

interface AgentListItem {
  app_group_id: string
  app_id: string
  application_type: string
  color: string
  copilot_conversation_id: string
  copilot_session_id: string
  created_at: string
  created_by: string
  creator_id: number
  description: string
  dingtalk_card_template_id: string
  draft_version_id: string
  flow_id: string
  flow_lock: number
  flow_name: string
  icon: string
  id: number
  is_bot: boolean
  is_deleted: boolean
  is_enable: boolean
  is_pinned: boolean
  is_public: boolean
  last_edited_at: string
  modified_by: string
  popularity: number
  recommended_sorting: number
  updated_at: string
  workspace_id: string
  workspace_name: string
}

export function fetchAgentList({ env, auth }: BaseBusinessType) {
  const request = new FetchRequest(auth, env)
  return request.get<ApiResponse<AgentListItem[]>>('/v1/list/agents')
}

interface AgentTemplateListItem {
  appGroupId: string
  applicationType: string
  color: string
  contentCount: number
  createBy: number
  creatorId: number
  datasetFileCount: number
  description: string
  flowId: string
  flow_lock: number
  icon: string
  id: string
  irlConfigStatus: boolean
  isEnable: boolean
  isPublic: boolean
  isPublish: boolean
  labels: string[]
  modifyBy: string
  name: string
  popularity: number
  recommendedSorting: number
  skillCount: number
  skillStatistics: {
    knowledge_files: number
    plugins: number
    flows: number
    database_tables: number
  }
  updateAt: string
  workspaceId: string
}
export function fetchTemplateAgentList({ env, auth }: BaseBusinessType) {
  const request = new FetchRequest(auth, env)
  return request.get<ApiResponse<AgentTemplateListItem[]>>(
    '/v1/applications/getTemplateList?applicationType=AGENT',
  )
}
