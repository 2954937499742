import classNames from 'classnames'
import type { Dispatch, MouseEvent, RefObject, SetStateAction } from 'react'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { useMemoizedFn } from 'ahooks'
import { createPortal } from 'react-dom'
import type { TipItem } from '../type'
import { wait } from '../../../../utils/wait'

interface Props {
  tips: TipItem[]
  tipsContainer?: HTMLElement | null
  onSelect: (index: number) => void
  editorDomRef: RefObject<HTMLElement>
}

export interface TipRef {
  getTipSelect: () => number
  setTipSelect: Dispatch<SetStateAction<number>>
}

export const Tip = forwardRef<TipRef, Props>((props: Props, ref) => {
  const { tips, tipsContainer, onSelect, editorDomRef } = props

  const [tipSelect, setTipSelect] = useState<number>(0)
  const [tipPosition, setTipPosition] = useState<[number, number]>()

  const getCursorPosition = useMemoizedFn(async () => {
    if (!tips || !tips.length) return
    await wait(300)
    if (!editorDomRef.current) return

    const cursorDom = editorDomRef.current.querySelector('.text-editor-cursor')
    if (!cursorDom) return
    const rect = cursorDom.getBoundingClientRect()
    const parentDom = tipsContainer || document.body
    const parentRect = parentDom.getBoundingClientRect()
    const scale = parentRect.height / parentDom.offsetHeight

    setTipPosition([
      (rect.top + rect.height - parentRect.top) / scale,
      (rect.left - parentRect.left) / scale,
    ])
  })

  const handleSelect = useMemoizedFn((event: MouseEvent, index: number) => {
    event.stopPropagation()
    event.preventDefault()
    onSelect(index)
  })

  const tipContent = useMemo(() => {
    if (!tipPosition || !tips.length) return null

    return (
      <div
        className='text-editor-tip'
        style={{ top: tipPosition[0], left: tipPosition[1] }}
      >
        {tips.map((each, index) => (
          <div
            key={index}
            className={classNames('text-editor-tip-item', {
              'text-editor-tip-item-hover': index === tipSelect,
            })}
            onMouseDown={e => handleSelect(e, index)}
          >
            {each?.render ? (
              each.render(each.value)
            ) : (
              <span style={{ color: each.color }}>{each.value}</span>
            )}
          </div>
        ))}
      </div>
    )
  }, [tips, tipPosition, tipSelect])

  useImperativeHandle(ref, () => ({
    getTipSelect() {
      return tipSelect
    },
    setTipSelect,
  }))

  useEffect(() => {
    if (!tips.length) {
      setTipPosition(undefined)
    } else {
      getCursorPosition()
    }
  }, [tips])

  return tipContent && createPortal(tipContent, tipsContainer || document.body)
})
