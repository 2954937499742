import { memo, useEffect, useState } from 'react'
import { Form, Input } from 'antd'
import { useMemoizedFn } from 'ahooks'

// import { JsonEditor, NodeForm } from '@/components'

// import type { FieldItem } from '@/features/nodes/start'
import { SchemaForm } from '@bty/ui-components'
import type { FormSchema } from '@bty/ui-components'
import type { ChatProps } from '@bty/chat-ui'
import { getFormItemComponentMap } from '@bty/chat-ui'
import { useChatAPI } from '../../hooks/useChatAPI'
import { useTaskData } from './TaskContext'
import { TaskType } from './const'

type FieldItem = any
interface TaskInputProps {
  className?: string
  title?: string
  disabled?: boolean
  onFileUpload?: ChatProps['onFileUpload']
}

interface IPluginItem {
  function_id: string
  name: string
  description: string
  display_name: string
  icon: string
  color: string
  package_name: string
  output: {
    comment: string
    ip_label: string
  }[]
  form_config: {
    description: string
    example: unknown
    type: string
    /**
     * 是否选择模型
     */
    llm_model?: boolean
    label: string
    required: boolean
    placeholder: string
    variableName: string
    value?: string
    variableType: string
    layout?: 'normal' | 'inline'
    tooltip?: string
  }[]
  action_type: 'INTEGRATION'
  type: 'PLUGIN'
  plugin_label: string
}

export function transformConfig2SchemaConfig(
  config: FieldItem[],
  extra?: Record<string, any>,
): FormSchema[] {
  return config.map(configItem => ({
    ...configItem,
    ...extra,
    name: configItem.variableName,
  }))
}

export const TaskInput = memo((props: TaskInputProps) => {
  const { className, title, disabled, onFileUpload } = props

  const {
    pluginListRef,
    form,
    inputTypeRef,
    varForm,

    subWebhooksJson,
    pubWebhooksJson,
  } = useTaskData()

  const taskType = Form.useWatch('execution_method', form)
  const taskFlowId = Form.useWatch('rel_flow_plugin_id', form)
  const taskPluginId = Form.useWatch('rel_flow_plugin_id', form)
  const [pluginList, setPluginList] = useState<IPluginItem[]>([])

  const [inputType, setInputType] = useState('form')
  const [formConfig, setFormConfig] = useState<any[]>([])
  const [webhookJson, setWebhookJson] = useState('')

  const handleChangeJson = useMemoizedFn((newValue: string) => {
    pubWebhooksJson(newValue)
  })

  const handleChangeFormConfig = useMemoizedFn((newConfig?: FieldItem[]) => {
    inputTypeRef.current = 'form'
    setInputType('form')
    setFormConfig(newConfig ?? [])
  })

  const { chatAPI } = useChatAPI()

  useEffect(() => {
    setFormConfig([])

    if (taskType === TaskType.FLOW && taskFlowId) {
      chatAPI.Agent.getFlowById(taskFlowId).then(res => {
        const startNode = res.data?.config.nodes.find(
          (each: any) => each.type === 'START',
        )

        if (startNode?.data?.type === 'webhook') {
          inputTypeRef.current = 'json'
          setInputType('json')
        } else {
          handleChangeFormConfig(res.data?.form_config)
        }
      })
      return
    }

    if (taskType === TaskType.PLUGIN && taskPluginId) {
      try {
        const pluginConfig = pluginListRef.current.find(
          e => e.function_id === taskPluginId,
        )
        if (pluginConfig) {
          // todo: ParametersWithInput type
          const initValue = (pluginConfig?.parameters as any)?.inputs?.reduce(
            (acc: any, cur: any) => {
              if (cur.default) {
                acc[cur.key] = cur.default
              }
              return acc
            },
            {} as Record<string, any>,
          )
          varForm.setFieldsValue(initValue)
        }
      } catch (e) {
        console.error(e)
      }

      const info = pluginList?.find(each => each.function_id === taskPluginId)
      if (!info) {
        chatAPI.Agent.getPluginList().then(res => {
          const list = res?.data ?? []
          setPluginList(list)
          const newInfo = list.find(
            (each: IPluginItem) => each.function_id === taskPluginId,
          )

          handleChangeFormConfig(newInfo?.form_config as FieldItem[])
        })
        return
      }

      handleChangeFormConfig(info?.form_config as FieldItem[])
    }
  }, [taskType, taskFlowId, taskPluginId])

  useEffect(() => {
    return subWebhooksJson(v => setWebhookJson(v ?? ''))
  }, [])

  if (!taskFlowId && !taskPluginId) return

  if (inputType === 'form' && (!formConfig || !formConfig.length)) return null

  return (
    <div className={className}>
      {title && <div className='font-600 mb-24px text-16px'>{title}</div>}

      {inputType === 'form' && (
        <SchemaForm
          disabled={disabled}
          className='border-none  [&_.node-form-item:last-of-type]:mb-0px! [&_.node-form-item_.pb-12]:pb-8 [&_.ant-select-multiple_.ant-select-selector]:h-auto! [&_.ant-select-multiple_.ant-select-selector]:pt-3px! [&_.ant-select-multiple_.ant-select-selector]:pb-0px! [&_.ant-select-multiple_.ant-select-selection-item]:h-28px! [&_.ant-select-multiple_.ant-select-selection-item]:mb-3px! [&_.ant-select-multiple_.ant-select-selection-item]:bg-[rgba(98,105,153,0.12)]!'
          form={varForm}
          fieldComponentsMap={getFormItemComponentMap(onFileUpload)}
          schema={transformConfig2SchemaConfig(formConfig, { size: 'middle' })}
        />
      )}

      {inputType === 'json' && (
        // <>todo json</>
        <Input.TextArea
          disabled={disabled}
          value={webhookJson}
          onChange={e => handleChangeJson(e.target.value)}
          autoSize={{ minRows: 5 }}
        />
        // <JsonEditor
        //   disabled={disabled}
        //   value={webhookJson}
        //   onChange={handleChangeJson}
        // />
      )}
    </div>
  )
})
