import { useMemo } from 'react'

interface AssetsProps {
  pathPrefix?: string
  path?: string
  fullPath?: string
  className?: string
}

export function Assets({
  path,
  fullPath,
  pathPrefix = 'https://resource.bantouyan.com/betteryeah/components',
  className,
}: AssetsProps) {
  const _path = useMemo(() => {
    return fullPath || (pathPrefix && path ? `${pathPrefix}/${path}` : path)
  }, [fullPath, path, pathPrefix])
  return <img src={_path} className={className} />
}
