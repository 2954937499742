import { memo, useEffect, useRef, useState } from 'react'
import { useBoolean, useMemoizedFn, useRequest } from 'ahooks'
import dayjs from 'dayjs'
import { Spin } from 'antd'
import type { TaskListResult } from '@bty/chat-fetch'
import { InfiniteScroll } from '@bty/components'
import { cn } from '../../utils/cn'
import { useChatAPI } from '../../hooks/useChatAPI'
import { Icon } from '../base/Icon'
import { TaskListItem } from './TaskListItem'
import { LogItem, LogTag } from './TaskLogItem'

interface TaskLogProps {
  agentId: string
  taskId: string
  readonly?: boolean
  isDelete?: boolean
  logModalPosition?: { top?: number; right?: number; bottom?: number }
  conversationId: string
  onUpdateList?: (refresh?: boolean, task?: TaskListResult) => void
  toEdit?: (task: TaskListResult) => void
  onBack?: () => void
  onClose?: () => void
}

export const TaskLog = memo((props: TaskLogProps) => {
  const {
    agentId,
    taskId,
    readonly,
    logModalPosition,
    isDelete = false,
    conversationId,
    onUpdateList,
    toEdit,
    onBack,
    onClose,
  } = props

  const [taskInit, { setTrue: setTaskInit }] = useBoolean(false)

  const { chatAPI } = useChatAPI()

  const { data: task, refresh: refreshTask } = useRequest(
    () => chatAPI.Task.getListDetail(taskId, conversationId, agentId),
    {
      ready: !!taskId,
      refreshDeps: [taskId],
      onFinally: setTaskInit,
    },
  )

  const { data: nextInfo } = useRequest(
    () => chatAPI.Task.getNextRunTime(taskId, conversationId),
    {
      ready: !!taskId,
      refreshDeps: [taskId],
    },
  )

  const ref = useRef<HTMLDivElement>(null)
  const pageNoRef = useRef(1)
  const [loading, setLoading] = useState(true)
  const [logs, setLogs] = useState<any[]>([])
  const [totalPage, setTotalPage] = useState(1)

  const handleLoadLog = useMemoizedFn(async () => {
    if (!taskId) return
    setLoading(true)
    const isFirst = pageNoRef.current === 1
    const { data: newLogs } = await chatAPI.Task.getLog(
      taskId,
      conversationId,
      pageNoRef.current,
    )
    setLogs(prev => {
      return isFirst ? newLogs.data_list : [...prev, ...newLogs.data_list]
    })
    setTotalPage(newLogs.total_pages)
    setLoading(false)
    pageNoRef.current += 1
  })

  const handleEdit = useMemoizedFn(() => {
    if (!task) return
    toEdit?.(task?.data)
  })

  useEffect(() => {
    if (logs.length) return
    handleLoadLog()
  }, [taskId])

  useEffect(() => {
    const handle = async () => {
      pageNoRef.current = 1
      handleLoadLog()
      refreshTask()
    }

    window.addEventListener('chatMessageUpdate', handle)
    return () => {
      window.removeEventListener('chatMessageUpdate', handle)
    }
  }, [])

  useEffect(() => {
    const handle = () => {
      refreshTask()
    }
    window.addEventListener('taskListUpdate', handle)

    return () => {
      window.removeEventListener('taskListUpdate', handle)
    }
  }, [])

  return (
    <div className='w-full flex flex-col h-full overflow-hidden'>
      <div className='flex items-center justify-center h-[64px] px-[16px] flex-none'>
        {onBack && (
          <button
            className='inline-flex items-center justify-center cursor-pointer w-[24px] h-[24px] hover:bg-bg_3 hover:bg-opacity-[0.08] rounded-[6px]  mr-[6px]'
            onClick={onBack}
          >
            <Icon path='back-black.svg' className='w-[16px]' />
          </button>
        )}

        <span className='font-[600] mr-auto text-16px'>任务详情</span>

        {onClose && (
          <button
            className='inline-flex items-center justify-center cursor-pointer w-[24px] h-[24px] hover:bg-bg_3 hover:bg-opacity-[0.08] rounded-[6px]'
            onClick={onClose}
          >
            <Icon path='close.svg' className='w-[16px]' />
          </button>
        )}
      </div>

      <Spin
        spinning={!taskInit && !isDelete}
        wrapperClassName={cn('h-full', { hidden: taskInit })}
      >
        {isDelete && (
          <div className='h-[60%] flex items-center justify-center flex-col'>
            <img
              className='w-128px'
              src='https://resource.bantouyan.com/betteryeah/business-comonents/empty.png'
              alt=''
            />
            <div className='text-font_1 text-opacity-60'>任务已被删除</div>
          </div>
        )}
      </Spin>

      {task && (
        <TaskListItem
          task={task?.data}
          readonly={readonly}
          conversationId={conversationId}
          onUpdateList={onUpdateList}
          onEdit={handleEdit}
          onDelete={onBack}
          className='!border-none !px-[20px] !pt-[0px] !pb-[16px] !mb-0 !cursor-unset'
        />
      )}

      {task?.data && (
        <div className='flex-1 overflow-hidden w-full' ref={ref}>
          <div
            className='overflow-auto overflow-x-hidden h-full px-[16px] w-full'
            // element='div'
            // options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
            // defer
          >
            <InfiniteScroll
              loading={loading}
              targetRoot={ref}
              hasMore={totalPage >= pageNoRef.current}
              loader={
                <div className='w-full h-[28px] r flex items-center justify-center'>
                  <Spin />
                </div>
              }
              load={handleLoadLog}
            >
              {task.data?.is_enable && nextInfo?.data?.next_run_time && (
                <div className='p-[16px] border-[1px] rounded-[8px] mb-[10px] border-line'>
                  <div className='flex items-center justify-center'>
                    <LogTag status='WAITING' />
                    <div className='mr-auto ml-[32px] text-[14px]'>
                      {dayjs(nextInfo.data?.next_run_time * 1000).format(
                        'YYYY-MM-DD HH:mm',
                      )}
                    </div>
                  </div>
                </div>
              )}
              {logs.map(each => (
                <LogItem
                  key={each.record_id}
                  log={each}
                  logModalPosition={logModalPosition}
                />
              ))}
            </InfiniteScroll>
            {!loading && !logs.length && !nextInfo?.data?.next_run_time && (
              <div className='h-[60%] flex items-center justify-center flex-col'>
                <img
                  className='w-[128px]'
                  src='https://resource.bantouyan.com/betteryeah/business-comonents/empty.png'
                  alt=''
                />
                <div className='text-font_1 text-opacity-60'>暂无执行日志</div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
})
