import { createContext, useMemo } from 'react'
import type { BaseBusinessType } from '../types/base'

type BusinessComponentContextProps = Pick<BaseBusinessType, 'auth' | 'env'>

export const BusinessComponentContext =
  createContext<BusinessComponentContextProps>({
    auth: {
      token: '',
      workspaceId: '',
      applicationId: '',
    },
    env: 'production',
  })

interface BusinessComponentProviderProps
  extends Pick<BaseBusinessType, 'auth' | 'env'> {
  children: React.ReactNode
}
export function BusinessComponentProvider({
  children,
  auth,
  env,
}: BusinessComponentProviderProps) {
  const value = useMemo(() => {
    return {
      auth,
      env,
    }
  }, [auth, env])

  return (
    <BusinessComponentContext.Provider value={value}>
      {children}
    </BusinessComponentContext.Provider>
  )
}
