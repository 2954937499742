import type { BotListProps } from '@bty/chat-ui'
import { BotList } from '@bty/chat-ui'
import { useBotList } from '@bty/chat-logic'
import { useChatAPI } from '../../hooks/useChatAPI'

export function BusinessBotList(props: Partial<BotListProps>) {
  const { chatAPI } = useChatAPI()

  const { doBotPin, doBotRemove, botList } = useBotList({
    chatApi: chatAPI,
    enable: true,
  })

  return (
    <BotList
      list={botList}
      {...props}
      hideSearch
      onPinedChange={(id, operation) => {
        props?.onPinedChange?.(id, operation)
        doBotPin(id, operation === 'pin')
      }}
      onRemove={id => {
        props?.onRemove?.(id)
        doBotRemove(id)
      }}
    />
  )
}
