import type { MutableRefObject, ReactNode } from 'react'
import { createContext, memo, useContext, useMemo, useRef } from 'react'
import { Form, type FormInstance } from 'antd'
import { useMemoizedFn } from 'ahooks'
import { omit } from 'lodash-es'
import { tryParseToObject } from '@bty/ai-shared'
import { useSubscribe } from '../../hooks/useSubscribe'

// import type { AgentFlowSkill, AgentListSystemSkillItem } from '@/apis/agent'
import { TaskType } from './const'

// todo: replace any with actual types
type AgentFlowSkill = any
type AgentListSystemSkillItem = any

interface TaskContextType {
  flowListRef: MutableRefObject<AgentFlowSkill[]>
  pluginListRef: MutableRefObject<AgentListSystemSkillItem[]>
  form: FormInstance
  varForm: FormInstance
  inputTypeRef: MutableRefObject<string>
  getRunParam: () => Promise<Record<string, any>>
  resetConfig: (task?: any) => void
  subWebhooksJson: (call: (value?: string) => void) => () => void
  pubWebhooksJson: (value: string) => void
}

export const TaskContext = createContext<TaskContextType>({
  flowListRef: { current: [] },
  pluginListRef: { current: [] },
  form: {} as FormInstance,
  varForm: {} as FormInstance,
  inputTypeRef: { current: '' },
  getRunParam: async () => ({}),
  resetConfig: () => {},
  subWebhooksJson: () => () => {},
  pubWebhooksJson: () => {},
})

interface TaskProviderProps {
  children: ReactNode
}

export const TaskProvider = memo((props: TaskProviderProps) => {
  const { children } = props

  const [form] = Form.useForm()
  const [varForm] = Form.useForm()
  const inputTypeRef = useRef('')
  const flowListRef = useRef<AgentFlowSkill[]>([])
  const pluginListRef = useRef<AgentListSystemSkillItem[]>([])

  const [subWebhooksJson, pubWebhooksJson, jsonRef] = useSubscribe<string>()

  const getRunParam = useMemoizedFn(async () => {
    if (inputTypeRef.current === 'json') {
      return tryParseToObject(jsonRef.current!)
    }
    await varForm.validateFields()
    return varForm.getFieldsValue()
  })

  const resetConfig = useMemoizedFn((task?: any) => {
    if (task) {
      if (task.execution_method !== TaskType.AGENT) {
        form.setFieldsValue(omit(task, 'run_param'))
        varForm.setFieldsValue(task.run_param)
        pubWebhooksJson(task.run_param)
      } else {
        form.setFieldsValue(task)
      }
    } else {
      form.resetFields()
      varForm.resetFields()
      pubWebhooksJson('')
    }
  })

  const value = useMemo(
    () => ({
      flowListRef,
      pluginListRef,
      form,
      varForm,
      inputTypeRef,
      getRunParam,
      resetConfig,
      subWebhooksJson,
      pubWebhooksJson,
    }),
    [form, inputTypeRef, varForm],
  )

  return <TaskContext.Provider value={value}>{children}</TaskContext.Provider>
})

export function useTaskData() {
  return useContext(TaskContext)
}
