import { Assets } from '@bty/components'
import { cn } from '../../../utils/cn'

interface AvatarProps {
  className?: string
  name?: string
  src?: string
  size?: 'small' | 'medium' | 'large'
}

export function Avatar({ className, name, src, size }: AvatarProps) {
  const innerSize = size || 'medium'

  const sizeClassNames = {
    'w-[80px] h-[80px]': innerSize === 'large',
    'w-[40px] h-[40px]': innerSize === 'medium',
    'w-[24px] h-[24px]': innerSize === 'small',
  }

  const innerSrc = src && src.startsWith('http') ? src : undefined

  if (innerSrc) {
    return (
      <img
        className={cn('rounded-full object-cover', sizeClassNames, className)}
        src={innerSrc}
      />
    )
  }

  if (name) {
    return (
      <div
        className={cn(
          'text-center bg-[#EAF3FD] rounded-full flex justify-center items-center',
          sizeClassNames,
          {
            'text-[34px]': innerSize === 'large',
            'text-[24px]': innerSize === 'medium',
            'text-[12px]': innerSize === 'small',
          },
          className,
        )}
      >
        {name?.[0]}
      </div>
    )
  }

  return (
    <Assets
      className={cn('rounded-full', sizeClassNames, className)}
      path='default-avatar.png'
    />
  )
}
