import { Assets } from '@bty/components'

interface IconProps {
  path?: string
  className?: string
}

const PATH_PREFIX =
  'https://resource.bantouyan.com/betteryeah/business-components'

export function Icon({ path, className }: IconProps) {
  return <Assets className={className} pathPrefix={PATH_PREFIX} path={path} />
}
